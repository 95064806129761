import React from 'react';
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate } from 'react-router-dom';
import logo from '../images/logo.png'
import axios from 'axios'
import prof from '../images/MyProfile/Profile.png'
import settings from '../images/MyProfile/Settings.png'
import terms from '../images/MyProfile/terms.png'
import logout from '../images/MyProfile/logout.png'
import arrow from '../images/MyProfile/arrow.png'
import fakeimg from '../images/MyProfile/ProfileImageFake.png'

const Profile = () => {

    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    var image;
    var file;
    var name;
    const dType = localStorage.getItem("dtype");
    console.log("dtype = "+dType);

    React.useEffect( () => {
        axios.get('https://api.thesscarpool.eu/api/Members/GetMemberProfile', { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res);
            //setRoutes(routes => res.data);
            sessionStorage.setItem("MyProfile",JSON.stringify(res.data));
        }).catch(err => {
            console.log(err);
        });
    });

    function onImageChange(event){
        console.log(event.target.files[0]);
        if (event.target.files && event.target.files[0]) {
            console.log(event.target.files[0].name);
            
            image = URL.createObjectURL(event.target.files[0]);
            file = event.target.files[0];
            name = event.target.files[0].name;
            document.getElementById('profile-pic').style.backgroundImage = "url("+image+")";

            const formData = new FormData();
            formData.append("FileName",name);
            formData.append("ImageFile",file);

            axios.post('https://api.thesscarpool.eu/api/Members/UpdateUserImage', formData, { headers: {"Authorization" : `Bearer ${token}`, "Content-Type":"multipart/form-data"} })
            .then(res => {
                console.log(res);
                //setRoutes(routes => res.data);
                //sessionStorage.setItem("MyProfile",JSON.stringify(res.data));
                navigate("/SuccessfullUserUpdate");
            }).catch(err => {
                console.log(err);
            });
        }
    };
    

    var profile = JSON.parse(sessionStorage.getItem("MyProfile"));
    
    return(
        <div className='Profile'>
            <NavLink className='back' to="/SearchRoute"></NavLink>
            <div className='ProfileDiv'>
                <div className='GenericProfileImageDiv'>
                {dType != 1 ? 
                    <div className='ProfileImage' id="profile-pic" style={{backgroundImage: profile.photoURL != null ? 
                        "url(https://admin.thesscarpool.eu/"+profile.photoURL+")":''}}>
                            {profile.photoURL == null 
                            ? <p style={{position:"absolute", height:"100%", width:"150px"}}>Αγγίξτε εδώ για να ανεβάσετε φωτογραφία</p>:''}
                            <input type="file" onChange={(e) => onImageChange(e)} className="input-image"></input>
                        </div> :
                        <div className='ProfileImage' id="profile-pic" style={{backgroundImage: "url("+logo+")"}}></div>
                }
                    
                </div>
                <div>
                    <h1 className='ProfileName'>{profile.firstName} <br/> {profile.lastName}</h1>
                    <div className='ProfileMail'>
                        <p>{profile.email}</p>
                    </div>
                    <div className='ProfilePhone'>
                        <p>{profile.phoneNumber}</p>
                    </div>
                </div>
            </div>
            <div className='ProfileChoises'>
                <div className='UserProfile' onClick={() => navigate("/MyProfile")}>
                    <img src={prof} style={{left:"15px",top:"10px"}}></img>
                    <p>Προφίλ Χρήστη</p>
                    <img src={arrow} style={{right:"15px",top:"10px"}}></img>
                </div>
                <div  className="DriverProfile" onClick={() => navigate("/DriverProfile")}>
                    <img src={prof} style={{left:"15px",top:"10px"}}></img>
                    <p>Προφίλ οδηγού</p>
                    <img src={arrow} style={{right:"15px",top:"10px"}}></img>
                </div>
                <div className="terms"  onClick={() => navigate("/Terms")}>
                    <img src={terms} style={{left:"15px",top:"10px"}}></img>
                    <p>Όροι χρήσης</p>
                    <img src={arrow} style={{right:"15px",top:"10px"}}></img>
                </div>
                <div className="deleteProfile"  onClick={() => navigate("/DeleteProfile")}>
                    <p>Διαγραφή λογοριασμού</p>
                </div>
                <div  className="logout" onClick={() => {localStorage.clear(); sessionStorage.clear(); navigate("/Login");}}>
                    <img src={logout} style={{left:"15px",top:"10px"}}></img>
                    <p>Αποσύνδεση</p>
                    <img src={arrow} style={{right:"15px",top:"10px"}}></img>
                </div>
            </div>
        </div>
    );
}

export default Profile