import React from 'react';
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate } from 'react-router-dom';
import logo from '../images/logo.png'
import axios from 'axios'
import prof from '../images/MyProfile/Profile.png'
import glass from '../images/Homepage/search.png';

const MyProfile = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const [detail, setDetail] = React.useState(null);
    const dType = localStorage.getItem("dtype");
    console.log("dtype = "+dType);

    React.useEffect( () => {
        axios.get('https://api.thesscarpool.eu/api/Members/GetMemberProfile', { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res);
            //setRoutes(routes => res.data);
            sessionStorage.setItem("MyProfile",JSON.stringify(res.data));
        }).catch(err => {
            console.log(err);
        });
    })

    var profile = JSON.parse(sessionStorage.getItem("MyProfile"));
    console.log(profile);

    const UpdateUser = () => {
       
        var firstName = document.getElementById("firstName").value;
        var lastName = document.getElementById("lastName").value;
        var city = document.getElementById("city").value;
        var address = document.getElementById("address").value;
        var phoneNumber = document.getElementById("phoneNumber").value;
        var firstName = document.getElementById("firstName").value;
        //var zipCode = document.getElementById("zipCode").value;
        //var sex = parseInt(document.getElementById("gender").value) == 0 || parseInt(document.getElementById("gender").value) == 1 ? parseInt(document.getElementById("gender").value) : null;
        var pass = document.getElementById("pass").value;
        //var confirm = document.getElementById("confirm").value;
        var mail = document.getElementById("mail").value;
        var sex = parseInt(document.getElementById("sex").value) == 0 || parseInt(document.getElementById("sex").value) == 1 ? parseInt(document.getElementById("sex").value) : null;
        var confirmPass = document.getElementById("confirmpass").value;
        var zipCode = document.getElementById("zipCode").value;

        
        if(pass != null){
            if(pass != confirmPass){
                setDetail("Τα password δεν ταιριάζουν μεταξύ τους");
                setTimeout(() => {
                    setDetail(null);
                },3000);
            }else{
                document.getElementById('loader').style.display = "inline-block";
                axios.post('https://api.thesscarpool.eu/api/Members/UpdateUserInfo', {
                "firstName": firstName,
                "lastName": lastName,
                "email": mail,
                "password": pass != null && pass == confirmPass ? pass : "",
                "address": address,
                "city": city,
                "zipCode": zipCode,
                "phoneNumber": phoneNumber,
                "deviceName": profile.deviceName,
                "deviceId": profile.deviceId,
                "deviceType": profile.deviceType,
                "photoURL":"url",
                "sex": sex,
            }
            , { headers: {"Authorization" : `Bearer ${token}`} })
            .then(res => {
                console.log(res);
                if(res.data == "Ok"){
                    axios.get('https://api.thesscarpool.eu/api/Members/GetMemberProfile', { headers: {"Authorization" : `Bearer ${token}`} })
                    .then(res => {
                        console.log(res);
                        if(res.status == 200){
                            navigate("/SuccessfullUserUpdate");
                        }
                        //setRoutes(routes => res.data);
                        sessionStorage.setItem("MyProfile",JSON.stringify(res.data));
                        }).catch(err => {
                            console.log(err);
                            
                            setDetail(err.response.data.detail);
                            setTimeout(() => {
                                setDetail(null);
                            },3000);
                        });

                    }
                    //setRoutes(routes => res.data);
                    sessionStorage.setItem("MyProfile",JSON.stringify(res.data));
                }).catch(err => {
                    console.log(err);
                });
            }
        }else{
            axios.post('https://api.thesscarpool.eu/api/Members/UpdateUserInfo', {
                "firstName": firstName,
                "lastName": lastName,
                "email": mail,
                "password": pass != null && pass == confirmPass ? pass : "",
                "address": address,
                "city": city,
                "zipCode": zipCode,
                "phoneNumber": phoneNumber,
                "deviceName": profile.deviceName,
                "deviceId": profile.deviceId,
                "deviceType": profile.deviceType,
                "photoURL":"url",
                "sex": sex,
            }
            , { headers: {"Authorization" : `Bearer ${token}`} })
            .then(res => {
                console.log(res);
                if(res.data == "Ok"){
                    axios.get('https://api.thesscarpool.eu/api/Members/GetMemberProfile', { headers: {"Authorization" : `Bearer ${token}`} })
                    .then(res => {
                        console.log(res);
                        if(res.status == 200){
                            navigate("/SuccessfullUserUpdate");
                        }
                        //setRoutes(routes => res.data);
                        sessionStorage.setItem("MyProfile",JSON.stringify(res.data));
                    }).catch(err => {
                        console.log(err);
                        
                        setDetail(err.response.data.detail);
                        setTimeout(() => {
                            setDetail(null);
                        },3000);
                    });

                    
                }
                //setRoutes(routes => res.data);
                sessionStorage.setItem("MyProfile",JSON.stringify(res.data));
            }).catch(err => {
                console.log(err);
            });
        }
        
    }

    var image;
    var file;
    var name;

    function onImageChange(event){
        console.log(event.target.files[0]);
        if (event.target.files && event.target.files[0]) {
            document.getElementById('loader').style.display = "inline-block";
            console.log(event.target.files[0].name);
            
            image = URL.createObjectURL(event.target.files[0]);
            file = event.target.files[0];
            name = event.target.files[0].name;
            document.getElementById('profile-pic').style.backgroundImage = "url("+image+")";

            const formData = new FormData();
            formData.append("FileName",name);
            formData.append("ImageFile",file);

            axios.post('https://api.thesscarpool.eu/api/Members/UpdateUserImage', formData, { headers: {"Authorization" : `Bearer ${token}`, "Content-Type":"multipart/form-data"} })
            .then(res => {
                console.log(res);
                //setRoutes(routes => res.data);
                //sessionStorage.setItem("MyProfile",JSON.stringify(res.data));
                navigate("/SuccessfullUserUpdate");
            }).catch(err => {
                console.log(err);
            });
        }
    };
    
    return(
        <div className='MyProfile'>
            <NavLink className='back' to="/Profile" style={{top:"20px"}}></NavLink>
            <h1 className='MyProfileH'>Το προφίλ μου</h1>
            <div className='ProfileImageDiv'>
                <div className='GenericProfileImageDiv' style={{position:"relative", left:"-10px"}}>

                   {dType != 1 ? 
                    <div className='ProfileImage' id="profile-pic" style={{backgroundImage: profile.photoURL != null ? 
                        "url(https://admin.thesscarpool.eu/"+profile.photoURL+")":''}}>
                            {profile.photoURL == null 
                            ? <p style={{position:"absolute", height:"100%", width:"150px"}}>Αγγίξτε εδώ για να ανεβάσετε φωτογραφία</p>:''}
                            <input type="file" onChange={(e) => onImageChange(e)} className="input-image"></input>
                        </div> :
                        <div className='ProfileImage' id="profile-pic" style={{backgroundImage: "url("+logo+")"}}></div>
                    }
                </div>
            </div>
            <div className='ProfileInfo'>
                <label style={{top:"10px"}}>'Ονομα</label>
                <input id="firstName" name="FirstName" type="text" defaultValue={profile.firstName}></input>

                <label style={{top:"10px"}}>Επώνυμο</label>
                <input id ="lastName" type="text" defaultValue={profile.lastName}></input>

                <label style={{top:"10px"}}>Email</label>
                <input id="mail" type="text" defaultValue={profile.email}></input>

                <label style={{top:"10px"}}>Password</label>
                <input id ="pass" type="password"></input>

                <label style={{top:"10px"}}>Επιβεβαίωση Password</label>
                <input id ="confirmpass" type="password"></input>

                <label style={{top:"10px"}}>Τηλέφωνο</label>
                <input id="phoneNumber" type="text" defaultValue={profile.phoneNumber}></input>

                <label style={{top:"10px"}}>Πόλη</label>
                <input id="city" type="text" defaultValue={profile.city}></input>

                <label style={{top:"10px"}}>Διεύθυνση</label>
                <input id="address" type="text" defaultValue={profile.address}></input>

                <label style={{top:"10px"}}>Ταχυδρομικός Κώδικας</label>
                <input id="zipCode" type="text" defaultValue={profile.zipCode}></input>
                
                <label style={{top:"10px"}}>Φύλλο</label>
                <select id="sex" defaultValue={profile.sex}>
                    <option value="0">Άνδρας</option>
                    <option value="1">Γυναίκα</option>
                </select>
            </div>
            <div className='SomethingWentWrong'>
                <div>{detail}</div>
            </div>
            <div className='UpdateDivButton'>
                <button onClick={UpdateUser}>Ενημέρωση</button>
            </div>
            <div id="loader" className="loader"></div>
        </div>
    );
}

export default MyProfile