import axios from 'axios'
import React from 'react'
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate, useLocation } from 'react-router-dom';
import star from '../images/Homepage/star.png'
import greenDot from '../images/Homepage/green_dot.png'
import blueDot from '../images/Homepage/blue_dot.png'
import line from '../images/Homepage/line.png'
import logo from '../images/logo.png'

const DriverRideInfo = () => {
    const location = useLocation();
    const token = localStorage.getItem("token");

    const { id } = location.state;
    const { tripId } = location.state;
    const { passengerId } = location.state;
    const { driverId } = location.state;
    const { vehicle } = location.state;
    const { driverFirstName } = location.state;
    const { driverLastName } = location.state;
    const { endRegionName } = location.state;
    const { startRegionName } = location.state;
    const { actualStartDate } = location.state;
    const { actualEndDate } = location.state;


    const DeleteReservation = () => {
        axios.delete('https://api.thesscarpool.eu/api/TripReservation/DeleteReservation/'+tripId, { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res);
            axios.get('https://api.thesscarpool.eu/api/Members/GetReservationsHistory', { headers: {"Authorization" : `Bearer ${token}`} })
            .then(res => {
                console.log(res.data);
                //setRoutes(routes => res.data);
                sessionStorage.setItem("PendingHistory",JSON.stringify(res.data));
            }).catch(err => {
                console.log(err);
            });
        }).catch(err => {
            console.log(err);
        });
    }

    return(
        <div className='PassengerRideInfo'>
            <NavLink className='back' to="/PendingHistoryRoutes"></NavLink>
            <div className='ProfileAndStars'>
                <div className='NameAndVehicle'>
                    <h3>{driverFirstName +" "+ driverLastName}</h3>
                    <p>οδηγεί ένα {vehicle}</p>
                </div>
                <div className='DriverStars'>
                    <img src={star}></img>
                    <p>4.9</p>
                </div>
            </div>
            <div className='PassengerRideInfoblackEclipse'>
                <div className='PassengerRideInfoRoutesDiv'>
                    <div className='PassengerRideInfoRoutesDivImages'>
                        <img src={greenDot} style={{left:"-1px",position:"relative"}}></img>
                        <img src={line} style={{height:"10px",top:"5px",position:"relative"}}></img>
                        <img src={blueDot} style={{position:"relative",top:"10px",left:"-3px"}}></img>
                    </div>
                    <div className='PassengerRideInfoRoutesDivRegions'>
                        <p className='RouteStart'>{startRegionName}</p>
                        <p className='RouteEnd'>{endRegionName}</p>
                    </div>
                </div>
                <hr style={{width:"100%"}}></hr>
                <div className='actualDates'>
                    <p>Ημερομηνία Αναχώρησης</p>
                    <p>{actualStartDate}</p>
                    <p>Ημερομηνία Επιστροφής</p>
                    <p>{actualEndDate}</p>
                </div>
                {/*<div className='Passengers'>
                    <p>επιβάτες στην διαδρομη σου</p>
                    <div className='PassengersProfiles'>
                        <img src={logo}></img>
                        <p className='PassengerName'>kostas</p>
                    </div>
                    <div className='PassengersProfiles'>
                        <img src={logo}></img>
                        <p className='PassengerName'>kostas</p>
                    </div>
                    <div className='PassengersProfiles'>
                        <img src={logo}></img>
                        <p className='PassengerName'>kostas</p>
                    </div>
                </div>
                <hr style={{width:"100%"}}></hr>
                <div className='DriverEvaluation'>
                    
                    <div className='EvaluationPassengerProfile'>
                        <img className='EvaluationPassengerImage' src={logo}></img>
                        <div className='EvaluationOfPassenger'>
                            <p className='passengerName'>kostas</p>
                            <p className='actualEvaluation'>very good i think</p>
                        </div>
                    </div>
                    <hr style={{width:"80%"}}></hr>
                    <div className='EvaluationPassengerProfile'>
                        <img className='EvaluationPassengerImage' src={logo}></img>
                        <div className='EvaluationOfPassenger'>
                            <p className='passengerName'>kostas</p>
                            <p className='actualEvaluation'>very good i think</p>
                        </div>
                    </div>
                    <hr style={{width:"80%"}}></hr>
                    <div className='EvaluationPassengerProfile'>
                        <img className='EvaluationPassengerImage' src={logo}></img>
                        <div className='EvaluationOfPassenger'>
                            <p className='passengerName'>kostas</p>
                            <p className='actualEvaluation'>very good i think</p>
                        </div>
                    </div>
                    <hr style={{width:"80%"}}></hr>
                    <div className='EvaluationPassengerProfile'>
                        <img className='EvaluationPassengerImage' src={logo}></img>
                        <div className='EvaluationOfPassenger'>
                            <p className='passengerName'>kostas</p>
                            <p className='actualEvaluation'>very good i think</p>
                        </div>
                    </div>
                    <hr style={{width:"80%"}}></hr>
                    <div className='EvaluationPassengerProfile'>
                        <img className='EvaluationPassengerImage' src={logo}></img>
                        <div className='EvaluationOfPassenger'>
                            <p className='passengerName'>kostas</p>
                            <p className='actualEvaluation'>very good i think</p>
                        </div>
                    </div>
                </div>*/}
                <div className='CancelDiv'>
                    <button onClick={() => DeleteReservation()}>Ακύρωση Δρομολογίου</button>
                </div>
            </div>
        </div>
    );
}

export default DriverRideInfo