//libraries
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate } from 'react-router-dom';
import axios from 'axios';
import React from 'react';
import Popup from 'reactjs-popup';
import Modal from './Modal';


const Register = () => {
const [detail, setDetail] = React.useState(null);
const [openModal, setOpenModal] = React.useState(false);
const navigate = useNavigate();

    const reg = () => { 
        
        var FirstName = document.getElementById("FirstName").value;
        var LastName = document.getElementById("LastName").value;
        var email = document.getElementById("email").value;
        var pass = document.getElementById("password").value;
        var confirm = document.getElementById("ConfirmPassword").value;
        var checked = document.getElementById("termsCheck").checked;
        var regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;

        console.log(checked);
        if(checked){
            if(regularExpression.test(pass)){
                if(pass != confirm){
                    setDetail("Τα password δεν είναι ίδια μεταξύ τους");
                    setTimeout(() => {
                        setDetail(null);
                    },3000);
                }else{
                    if(/\s/.test(email)){
                        setDetail("Το email σας περιέχει κενό");
                        setTimeout(() => {
                            setDetail(null);
                        },3000);
                    }else{
                        //disable button
                        document.getElementById("regButton").disabled = true;
                        document.getElementById('loader').style.display = "inline-block";
                        axios.post(`https://api.thesscarpool.eu/api/Members/Register`, {
                            "firstName": FirstName,
                            "lastName": LastName,
                            "email": email,
                            "password": pass,
                            "address": "string",
                            "city": "",
                            "zipCode": "",
                            "phoneNumber": "",
                            "lastUpdatedOn": "2022-05-23T13:04:54.502Z",
                            "sex": 0,
                            "deviceName": "",
                            "deviceId": "",
                            "deviceType": 0
                        })
                        .then(res => {
                                console.log(res);
                                //localStorage.setItem("token",res.data.token);
                                if(res.data.token){
                                    navigate("/SuccessfullRegister");
                                }
                        }).catch(error => {
                                console.log(error);
                                document.getElementById('loader').style.display = "none";
                                setDetail(error.response.data.detail);
                                
                                setTimeout(() => {
                                    setDetail(null);
                                },3000);
                                document.getElementById("regButton").disabled = false;
                                
                        });
                    }   
                }
            }else{
                setDetail("Το password πρέπει να αποτελείται τουλάχιστον από 8 χαρακτήρες, εκ των οποίων ένα κεφαλαίο γράμμα, ένα σύμβολο και έναν αριθμό");
                setTimeout(() => {
                    setDetail(null);
                },3000);
            }
        }else{
            setDetail("Πρέπει να αποδεχτείτε τους όρους χρήσης");
            setTimeout(() => {
                setDetail(null);
            },3000);
        }

        
    }

    return(
        <div className='RegisterDivMain'>
           <NavLink className='WhiteBack' to="/Login"></NavLink>
           <div className='LoginDivSpace' style={{height:"50px"}}>

             </div>
            <div className='RegisterDivOne'>
                <h2 className='RegisterDivH'>Δημιουργία <br/> Λογαριασμού</h2>
            </div>
            
            <div className='RegisterDivCredentials'>
                <input id="FirstName" className='CredentialsName' placeholder='Όνομα' type='text'></input>
                <input id="LastName" className='CredentialsName' placeholder='Επώνυμο' type='text' style={{marginTop:"15px"}}></input>
                <input id="email" className='CredentialsUser' placeholder='Email' type='text'></input>
                <input id="password" className='CredentialsPass' placeholder='Password' type='password'></input>
                <input id="ConfirmPassword" className='CredentialsPass' placeholder='Επιβεβαίωση Password' type='password'></input>
                <input id="phone" className='CredentialsUser' placeholder='Τηλέφωνο Επικοινωνίας' type='text'></input>
                <div className='RememberMeCheck'>
                    <input type='checkbox' id="termsCheck"></input>
                    <p style={{color:"white"}}>Αποδέχομαι τους <button 
                                                                        onClick={() => setOpenModal(true)} 
                                                                        className='modalButton'>
                                                                         Όρους χρήσης
                                                                    </button>
                    </p>
                </div>
            </div>
            
            <div className='RegisterDivButton'>
                <button id="regButton" onClick={() => reg()}>Εγγραφή</button>
            </div>
            <div id="loader" className="loader"></div>
            
            <div className='SomethingWentWrongRegister'>
                <p>{detail}</p>
            </div>
            <Modal 
            open={openModal} 
            onClose={() => setOpenModal(false)} />
        </div>
    );
}

export default Register;