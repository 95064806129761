//libraries
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate } from 'react-router-dom';
import axios from 'axios';
import React from 'react';
import glass from '../../images/Homepage/search.png';
import greenDot from '../../images/Homepage/green_dot.png'
import blueDot from '../../images/Homepage/blue_dot.png'
import line from '../../images/Homepage/line.png'

//files
import calendar from '../../images/Homepage/CombinedShape.png'
import passenger from '../../images/Homepage/Union.png'
import { act } from 'react-dom/test-utils';

const token = localStorage.getItem("token");

const OfferRoute = () => {
    const OfferStart = sessionStorage.getItem("OfferStart") || null;
    const OfferDestination = sessionStorage.getItem("OfferDestination") || null;
    const [detail, setDetail] = React.useState(null);
    const navigate = useNavigate();

    const goToCalendar = () => {
        navigate("/OfferRouteCalendar");
    }

    React.useEffect( () => {
        axios.get('https://api.thesscarpool.eu/api/TripReservation/NotificationForReservation', { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res);
            sessionStorage.setItem("Notifications",JSON.stringify(res.data));
        })
        .catch(err => {
            console.log(err);
        });
    });

    const PostRoute = () => {
        /*var date = sessionStorage.getItem("date");
        var start = sessionStorage.getItem("start");
        var end = sessionStorage.getItem("destination");
        console.log(start+end+date);
        console.log(date);
        axios.post('https://api.thesscarpool.eu/api/Trips/GetAll/'+date+"/"+start+"/"+end)
        .then(res => {
            console.log(res.data);
            sessionStorage.setItem("searchDriveResults",JSON.stringify(res.data));
            navigate("/RouteResults");
        }).catch(err => {
            console.log(err.response.data);
        })*/
        
        var postDate =  sessionStorage.getItem("OfferRouteDate");
        var endDate =  sessionStorage.getItem("OfferRouteDateEnd");
        var postStart = sessionStorage.getItem("OfferStart");
        var postDestination = sessionStorage.getItem("OfferDestination");
        var details = "none";

        console.log("date: => "+postDate);
        console.log("start: => "+postStart);
        console.log("destination: => "+postDestination);

        axios.get('https://api.thesscarpool.eu/api/Route/PostRoute/'+postStart+"/"+postDestination+"/"+details, { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res);
            axios.get('https://api.thesscarpool.eu/api/Route/GetMyRoutes', { headers: {"Authorization" : `Bearer ${token}`} })
            .then(res => {
                console.log(res.data);
                //setRoutes(routes => res.data);
                sessionStorage.setItem("MyRoutes",JSON.stringify(res.data));
            }).catch(err => {
                console.log(err);
            });
            setDetail(res.data);
            setTimeout(() => {
                setDetail(null);
            },3000);
            //sessionStorage.setItem("searchDriveResults",JSON.stringify(res.data));
            //navigate("/RouteResults");
        }).catch(err => {
            console.log(err);
            setDetail(err);
            setTimeout(() => {
                setDetail(null);
            },3000);
        });
    }
    
    return (
        <>
        <div className='StartDestination'>
                <div className='HomepageRoutesDiv'>
                    <div className='HomepageRoutesDivImages'>
                        <img src={greenDot} style={{left:"-1px",position:"relative"}}></img>
                        <img src={line} style={{height:"40px",top:"5px",position:"relative"}}></img>
                        <img src={blueDot} style={{position:"relative",top:"10px",left:"-3px"}}></img>
                        
                    </div>
                    <div className='HomepageRoutesDivRegions'>
                        <NavLink to="/OfferRouteStart" style={{marginLeft:"-10px"}}>Αφετηρία</NavLink>
                        <p>{OfferStart != null ? OfferStart : 'Αφετηρία'}</p>
                        <hr></hr>
                        <NavLink to="/OfferRouteDestination">Προορισμός</NavLink>
                        <p>{OfferDestination != null ? OfferDestination: 'Προορισμός'}</p>
                    </div>
                </div>
            </div>
            <div className='SomethingWentWrongHomepage'>
                <p>{detail}</p>
            </div>
            <div className='blackEclipse'>
                <div className='HomepageReservation'>
                    <div onClick={() => goToCalendar()} className='HomepageCalendar'>
                        <img src={calendar}></img>
                        <div className='date'>
                            <p className='day'>{sessionStorage.getItem("OfferRouteDate") != null ? sessionStorage.getItem("OfferRouteDate") : 'Σήμερα'}</p>
                            <p className='hour'>{sessionStorage.getItem("OfferTime") != null ? sessionStorage.getItem("OfferTime") : '8:00'}</p>
                        </div>
                    </div>
                </div>
                <div className='searchButtonDiv'>
                    <button onClick={PostRoute}>Ορισμός διαδρομής</button>
                </div>
                
                <div className='menu'>
                    <div className='searchButtonMenu'>
                        <button onClick={() => navigate("/OfferRouteStart")}>
                            <img src={glass}></img>
                        </button>
                    </div>
                    <NavLink to="/PendingHistoryRoutes" className='car'></NavLink>
                    <NavLink to="/ChatList" className='messages'></NavLink>
                    <NavLink to="/Notifications" className='notifications'></NavLink>
                    <NavLink to="/Profile" className='profile'></NavLink>
                </div>
            </div>
        </>
    );
}

export default OfferRoute;