import React from 'react';
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate, useLocation, useParams } from 'react-router-dom';
import axios, { CanceledError } from 'axios';

const DeleteProfile = () => {
    const token = localStorage.getItem("token");
    const [detail, setDetail] = React.useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    const Ok = () => {
        axios.get('https://api.thesscarpool.eu/api/Members/DeleteMember', { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res);
            if(res.status == 200){
                setDetail("Ο λογαριασμός σας διαγράφηκε με επιτυχία");
                setTimeout(() => {
                    setDetail(null);
                    navigate("/Login");
                },3000);
            }
            //setRoutes(routes => res.data);
            //sessionStorage.setItem("TripByRouteId",JSON.stringify(res.data));
        }).catch(err => {
            console.log(err);
        });
    }

    const Cancel = () => {
        navigate("/Profile")
    }

    return(
        <div className='SuccessfullReservationMainDiv'>
            <h1>Είστε σίγουροι ότι θέλετε να διαγράψετε τον λογαριασμό σας; Δεν θα μπορέσετε να αναιρέσετε αυτήν την ενέργεια.</h1>
            <p style={{color:"red"}}>{detail}</p>
            <div className='SuccessfullButton'>
                <button style={{right:"10px"}} onClick={() => Ok()}>Ναι</button>
                <button style={{left:"10px"}} onClick={() => Cancel()}>Ακύρωση</button>
            </div>
        </div>
    );
}

export default DeleteProfile;