import React from 'react';
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';

const SuccessfullConfirmation = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const {name} = location.state;

    const Ok = () => {
        navigate("/SearchRoute");
    }

    return(
        <div className='SuccessfullReservationMainDiv'>
            <h1>Εσείς και ο {name} είστε πλεον φίλοι</h1>
            <div className='SuccessfullButton'>
                <button onClick={Ok}>OK</button>
            </div>
        </div>
    );
}

export default SuccessfullConfirmation;