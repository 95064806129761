//libraries
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate } from 'react-router-dom';
import axios from 'axios';
import React from 'react';

const Login = () => {
const [detail, setDetail] = React.useState(null);
const navigate = useNavigate();
const dType = localStorage.getItem("dtype");



    const log = () => {
        var mail = document.getElementById("mail").value;
        var pass = document.getElementById("pass").value;
        document.getElementById('loader').style.display = "inline-block";
        axios.post('https://api.thesscarpool.eu/api/Members/Login', {
            "email": mail,
            "password": pass
        })
          .then(res => {
                console.log(res);
                var token = res.data.token;
                localStorage.setItem("token",res.data.token);

                axios.get('https://api.thesscarpool.eu/api/Members/GetMemberProfile', { headers: {"Authorization" : `Bearer ${token}`} })
                .then(res => {
                    console.log(res.data);
                    //setRoutes(routes => res.data);
                    sessionStorage.setItem("MyProfile",JSON.stringify(res.data));
                }).catch(err => {
                    console.log(err);
                });

                axios.get('https://api.thesscarpool.eu/api/Driver/GetDriverProfile', { headers: {"Authorization" : `Bearer ${token}`} })
                .then(res => {
                    console.log(res);
                    //setRoutes(routes => res.data);
                    sessionStorage.setItem("DriverProfile",JSON.stringify(res.data));
                }).catch(err => {
                    console.log(err);
                });

                axios.get('https://api.thesscarpool.eu/api/Members/GetReservationsHistory', { headers: {"Authorization" : `Bearer ${token}`} })
                .then(res => {
                    console.log(res.data);
                    //setRoutes(routes => res.data);
                    sessionStorage.setItem("PendingHistory",JSON.stringify(res.data));
                }).catch(err => {
                    console.log(err);
                });

                axios.get('https://api.thesscarpool.eu/api/Route/GetMyRoutes', { headers: {"Authorization" : `Bearer ${token}`} })
                .then(res => {
                    console.log(res.data);
                    //setRoutes(routes => res.data);
                    sessionStorage.setItem("MyRoutes",JSON.stringify(res.data));
                }).catch(err => {
                    console.log(err);
                });

                axios.get('https://api.thesscarpool.eu/api/Regions/GetAll', { headers: {"Authorization" : `Bearer ${token}`} })
                .then(res => {
                    //console.log(res);
                    sessionStorage.setItem("Regions", JSON.stringify(res.data));
                })
                .catch(err => {
                    console.log(err);
                });

                axios.get('https://api.thesscarpool.eu/api/Members/GetReservationsHistory', { headers: {"Authorization" : `Bearer ${token}`} })
                .then(res => {
                    console.log(res.data);
                    
                    //setRoutes(routes => res.data);
                    sessionStorage.setItem("PendingHistory",JSON.stringify(res.data));
                }).catch(err => {
                    console.log(err);
                });

                axios.get('https://api.thesscarpool.eu/api/Trips/GetMyTrips', { headers: {"Authorization" : `Bearer ${token}`} })
                .then(res => {
                    console.log(res.data);
                    
                    //setRoutes(routes => res.data);
                    sessionStorage.setItem("MyTrips",JSON.stringify(res.data));
                }).catch(err => {
                    console.log(err);
                });

                axios.get('https://api.thesscarpool.eu/api/Members/GetFriends', { headers: {"Authorization" : `Bearer ${token}`} })
                .then(res => {
                    //console.log(res);
                    //console.log(res.data.hasFriends.$values.length);
                    //console.log(res.data.isFriendWith.$values.length);
                    if(res.data.isFriendWith.$values.length > 0){
                        sessionStorage.setItem("FriendsIds",JSON.stringify(res.data.isFriendWith));
                    }

                    if(res.data.hasFriends.$values.length > 0){
                        sessionStorage.setItem("FriendsIds",JSON.stringify(res.data.hasFriends));
                    }
                })
                .catch(err => {
                    console.log(err);
                });

                axios.get('https://api.thesscarpool.eu/api/TripReservation/NotificationForReservation', { headers: {"Authorization" : `Bearer ${token}`} })
                .then(res => {
                    console.log(res);
                    sessionStorage.setItem("Notifications",JSON.stringify(res.data));
                })
                .catch(err => {
                    console.log(err);
                });


                //send token to android
                if(window.AndroidFunction){
                    window.AndroidFunction.SaveToken(token);
                }

                //send token to ios
                if(dType && dType == 1){
                    window.webkit.messageHandlers.saveUserToken.postMessage(JSON.stringify({token}));
                }

                if(res.data.token){
                    navigate("/SearchRoute");
                }
          }).catch(error => {
                if(error.response.data.status == 401){
                    setDetail("Λάθος στοιχεία σύνδεσης");
                }else{
                    setDetail(error.response.data.detail);
                }
                console.log(error);
                
                setTimeout(() => {
                    setDetail(null);
                },3000);
          });
    }

    return(
        <div className='LoginDivMain'>
             <div className='LoginDivSpace'>

             </div>
            <div className='LoginDivOne'>
                <h2 className='LoginDivH'>Σύνδεση!</h2>
                <p className='LoginDivP'>Παρακαλούμε συνδεθείτε στο λογαριασμό σας </p>
            </div>
            <div className='LoginDivCredentials'>
                <input id="mail" className='CredentialsUser' placeholder='Email' type='text'></input>
                <input id="pass" className='CredentialsPass' placeholder='Password' type='password'></input>
            </div>
            <div className='LoginRememberMe'>
                <div className='RememberMeCheck'>
                    <input type='checkbox'></input>
                    <p>Θυμήσου με</p>
                </div>
                <NavLink to="/ForgotPass">Ξέχασες το Password</NavLink>
            </div>
            <div className='LoginDivButton'>
                <button onClick={() => log()}>Σύνδεση</button>
            </div>
            <div className='LoginDivRegister'>
                <p>Δεν έχεις λογαριασμό; <NavLink to="/Register"> Εγγραφή</NavLink></p> 
            </div>
            <div className='SomethingWentWrong'>
                <p>{detail}</p>
            </div>
            <div id="loader" className="loader"></div>
        </div>
    );
}

export default Login;