import OnBoardingOneBg from '../images/OnBoarding/OnBoardingTwoBg.png';
import EllipseOne from '../images/OnBoarding/Ellipse125.png'
import EllipseTwo from '../images/OnBoarding/Ellipse126.png'
import Rectangle from '../images/OnBoarding/Rectangle82.png'

//libraries
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes } from 'react-router-dom';

const OnBoardingTwo = () => {
    return(
        <div className='OnBoardingTwo'>
            <NavLink className='OnBoardingTwoNext' to="/OnBoardingThree">Επόμενο</NavLink>
            <div className='OnBoardingTwoDiv'>
                <h5 className='OnBoardingTwoH'>Μοιράσου το κόστος και διασκέδασε το</h5>
                <p className='OnBoardingTwoP'>Μείωσε τα έξοδα των μετακινήσεων σου και διασκέδασε με τους συνεπιβάτες σου</p>
            </div>
            <div className='OnBoardingTwoDivImg'>
                <img src={EllipseOne}></img>
                <img src={Rectangle}></img>
                <img src={EllipseTwo}></img>
            </div>
            <div className='OnBoardingTwoDivLogin'>
                <p>Έχεις ήδη λογαριασμό; <NavLink to="/Login"> Συνδέσου εδώ.</NavLink></p> 
            </div>
        </div>
    );
}

export default OnBoardingTwo;