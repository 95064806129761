import './App.css';
import Splash from './startFolder/Splash';
import OnBoardingOne from './startFolder/OnBoardingOne';
import OnBoardingTwo from './startFolder/OnBoardingTwo';
import OnBoardingThree from './startFolder/OnBoardingThree';
import Login from './Authentication/Login';
import Start from './Homepages/SearchRoute/Start.tsx';
import Destination from './Homepages/SearchRoute/Destination.tsx';
import Register from './Authentication/Register';
import ForgotPass from './Authentication/ForgotPass';
import SendMailSuccess from './Authentication/SendMailSuccess';
import Homepage from './Homepages/Homepage';
import PickDate from './Homepages/SearchRoute/Calendar';
import RouteResults from './Homepages/RouteResults';
import ConfirmRoute from './Homepages/ConfirmRoute';
import SeatBooking from './Homepages/SeatBooking';
import SuccessfullReservation from './Homepages/SuccessfullReservation';
import HistoryRoutes from './Homepages/HistoryRoutes';
import OfferRouteStart from './Homepages/OfferRoute/OfferRouteStart.tsx';
import OfferRouteDestination from './Homepages/OfferRoute/OfferRouteDestination.tsx';
import OfferRoute from './Homepages/OfferRoute/OfferRoute';
import MyRoutes from './Homepages/MyRoutes';
import PendingHistoryRoutes from './Homepages/PendingHistoryRoutes';
import MyProfile from './Profile/MyProfile';
import Profile from './Profile/Profile';
import OfferPickDateNewStart from './Homepages/NewRouteCalendars/CalendarStart';
import OfferPickDateNewEnd from './Homepages/NewRouteCalendars/CalendarEnd';
import PassengerRideInfo from './Homepages/PassengerRideInfo';
import DriverRideInfo from './Homepages/DriverRideInfo';
import SuccessfullReservationDelete from './Homepages/SuccessfullReservationDelete';
import EditRoute from './Homepages/EditRoute';
import SuccessfullRouteDelete from './Homepages/SuccessfullRouteDelete';
import ChatList from './Chat/ChatList';
import Terms from './Profile/Terms';
import DriverProfile from './Profile/DriverProfile';
import Notifications from './Homepages/Notifications';
import SuccessfullConfirmation from './Homepages/SuccessfullConfirmation';
import SuccessfullRegister from './Authentication/SuccessfullRegister';
import SuccessfullUserUpdate from './Homepages/SuccessfullUserUpdate';
import OfferRouteCalendarStart from './Homepages/OfferRoute/OfferRouteCalendarStart';
import OfferRouteCalendarEnd from './Homepages/OfferRoute/OfferRouteCalendarEnd';
import PickDateBack from './Homepages/SearchRoute/CalendarBack';
import SuccessfullRouteAdd from './Homepages/SuccessfullRouteAdd';
import EditMyTrip from './Homepages/EditMyTrip';
import DeleteFriends from './Chat/DeleteFriends';
import DeleteTrip from './Homepages/DeleteTrip';
import DeleteReservation from './Homepages/DeleteReservation';
import DeleteRoute from './Homepages/DeleteRoute';
import DeleteProfile from './Profile/DeleteProfile';
import SuccessfullRouteUpdate from './Homepages/SuccessfullRouteUpdate';
import SuccessfullTripAdd from './Homepages/SuccessfullTripAdd';

//libraries
import { BrowserRouter, Route, NavLink, Link, Navbar, Routes } from 'react-router-dom';
import { passiveSupport } from 'passive-events-support/src/utils';
/*passiveSupport({
  debug: false,
  // add this one
  listeners: [
    {
      element: 'div',
      event: 'touchstart',
      prevented: true
    },
    {
      element: 'div',
      event: 'touchmove',
      prevented: true

    }
  ]
})*/


function App() {
  return (
    <div className="App">
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Splash />} />
        <Route path="/OnBoardingOne" element={<OnBoardingOne />} />
        <Route path="/OnBoardingTwo" element={<OnBoardingTwo />} />
        <Route path="/OnBoardingThree" element={<OnBoardingThree />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Start" element={<Start />} />
        <Route path="/Destination" element={<Destination />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/ForgotPass" element={<ForgotPass />} />
        <Route path="/SendMailSuccess" element={<SendMailSuccess />} />
        <Route path="/Homepage" element={<Homepage />} />
        <Route path="/Calendar" element={<PickDate />} />
        <Route path="/RouteResults" element={<RouteResults />} />
        <Route path="/ConfirmRoute" element={<ConfirmRoute />} />
        <Route path="/SeatBooking" element={<SeatBooking />} />
        <Route path="/SuccessfullReservation" element={<SuccessfullReservation />} />
        <Route path="/HistoryRoutes" element={<HistoryRoutes />} />
        <Route path="/OfferRouteStart" element={<OfferRouteStart />} />
        <Route path="/OfferRouteDestination" element={<OfferRouteDestination />} />
        <Route path="/MyProfile" element={<MyProfile />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/CalendarStart" element={<OfferPickDateNewStart />} />
        <Route path="/CalendarEnd" element={<OfferPickDateNewEnd />} />
        <Route path="/PassengerRideInfo" element={<PassengerRideInfo />} />
        <Route path="/DriverRideInfo" element={<DriverRideInfo />} />
        <Route path="/SuccessfullReservationDelete" element={<SuccessfullReservationDelete />} />
        <Route path="/EditRoute" element={<EditRoute />} />
        <Route path="/SuccessfullRouteDelete" element={<SuccessfullRouteDelete />} />
        <Route path="/ChatList" element={<ChatList />} />
        <Route path="/Terms" element={<Terms />} />
        <Route path="/DriverProfile" element={<DriverProfile />} />
        <Route path="/Notifications" element={<Notifications />} />
        <Route path="/SuccessfullConfirmation" element={<SuccessfullConfirmation />} />
        <Route path="/SuccessfullRegister" element={<SuccessfullRegister />} />
        <Route path="/OfferRouteCalendarStart" element={<OfferRouteCalendarStart />} />
        <Route path="/OfferRouteCalendarEnd" element={<OfferRouteCalendarEnd />} />
        <Route path="/CalendarReturn" element={<PickDateBack />} />
        <Route path="/SuccessfullRouteAdd" element={<SuccessfullRouteAdd />} />
        <Route path="/SuccessfullUserUpdate" element={<SuccessfullUserUpdate />} />
        <Route path="/EditMyTrip" element={<EditMyTrip />} />
        <Route path="/DeleteFriends" element={<DeleteFriends />} />
        <Route path="/DeleteTrip" element={<DeleteTrip />} />
        <Route path="/DeleteReservation" element={<DeleteReservation />} />
        <Route path="/DeleteRoute" element={<DeleteRoute />} />
        <Route path="/DeleteProfile" element={<DeleteProfile />} />
        <Route path="/SuccessfullRouteUpdate" element={<SuccessfullRouteUpdate />} />
        <Route path="/SuccessfullTripAdd" element={<SuccessfullTripAdd />} />

        <Route path="/OfferRoute" element={
          <Homepage>
            <OfferRoute></OfferRoute>
          </Homepage>
          } />

        <Route path="/SearchRoute" element={
          <Homepage>
            <OfferRoute></OfferRoute>
          </Homepage>
          } />

        <Route path="/MyRoutes" element={
          <HistoryRoutes>
            <MyRoutes></MyRoutes>
          </HistoryRoutes>
          } />
        <Route path="/PendingHistoryRoutes" element={
          <HistoryRoutes>
            <PendingHistoryRoutes></PendingHistoryRoutes>
          </HistoryRoutes>
          } />

      </Routes>
    </BrowserRouter>
      </div>
  );
}

export default App;
