import React from 'react';
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';

const SuccessfullReservationDelete = () => {
    const navigate = useNavigate();

    const Ok = () => {
        navigate("/PendingHistoryRoutes");
    }

    return(
        <div className='SuccessfullReservationMainDiv'>
            <h1>Η ακύρωση της θέσης έγινε!</h1>
            <p>Θα ειδοποιήσουμε τον οδηγό για την ενέργεια σας</p>
            <div className='SuccessfullButton'>
                <button onClick={Ok}>OK</button>
            </div>
        </div>
    );
}

export default SuccessfullReservationDelete;