import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate } from 'react-router-dom';
import prof from '../images/MyProfile/Profile.png'
import termsInside from '../images/MyProfile/termsInside.png'
import axios from 'axios'
import React from 'react'; 

const Terms = () => {

    const [terms, setTerms] = React.useState(null);

    React.useEffect(() => {
        axios.get("https://api.thesscarpool.eu/api/Members/GetPageContent?PageId=1")
        .then(res => {
            console.log(res.data);
            setTerms(res.data);
        })
        .catch(error => {
            console.log(error);
        });
    })

    return(
        <div className='Terms'>
            <NavLink className='back' to="/Profile"></NavLink>
            <h1 className='MyProfileH'>Terms</h1>
            <div className='TermsImageDiv'>
                <img src={termsInside}></img>
            </div>
            <div className='ProfileInfo'>
            <p style={{overflowY:"auto", overflowX:"hidden"}}
                dangerouslySetInnerHTML={{__html: terms}}
                ></p>
            </div>
        </div>
    );
}

export default Terms;