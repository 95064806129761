import React from 'react';
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate, useLocation, useParams } from 'react-router-dom';
import logo from '../images/logo.png'
import greenDot from '../images/Homepage/green_dot.png'
import blueDot from '../images/Homepage/blue_dot.png'
import line from '../images/Homepage/line.png'
import star from '../images/Homepage/star.png'

const ConfirmRoute = () => {
    const navigate = useNavigate();
    const { handle } = useParams();
    const location = useLocation();
    //console.log(location);
    const { id } = location.state;
    const { driverFirstName } = location.state;
    const {  driverLastName } = location.state;
    const { routeId } = location.state;
    const {  startRegion } = location.state;
    const {  endRegion } = location.state;
    const {  carModel } = location.state;
    const {  date } = location.state;
    

    console.log(routeId.id);

    const seat = () => {
        navigate("/SeatBooking",{
            tripId: id
        });
    }
  

    return(
        <div className='confirmRouteMainDiv'>
            <NavLink className='back' to="/SearchRoute"></NavLink>
            <h1>Επιβεβαίωση ενδιαφέροντος</h1>
             <div key={id} className="routes" style={{top:"70px", height:"180px"}}>
                <div className='driverProfile'>
                    <img className='driverImage' src={logo}></img>
                    <div className='driverInfo'>
                        <p className='driverName'>{driverFirstName +" "+ driverLastName}</p>
                        <p className='driverCar'>{carModel}</p>
                    </div>
                </div>
                <div className='ConfirmRouteDate'>
                    <p>{date.slice(0,-9)}</p>
                </div>
                <div className='ConfirmRoutesDiv'>
                    <div className='ConfirmRouteTime'>
                        <p>{date.slice(11,-3)}</p>
                    </div>
                    <div className='ConfirmRoutesDivImages'>
                        <img src={greenDot} style={{left:"-1px",position:"relative"}}></img>
                        <img src={line} style={{height:"30px",top:"5px",position:"relative"}}></img>
                        <img src={blueDot} style={{position:"relative",top:"10px",left:"-3px"}}></img>
                    </div>
                    <div className='ConfirmRoutesDivRegions'>
                        <p className='RouteStart'>{startRegion}</p>
                        <hr style={{width:"230%"}}></hr>
                        <p className='RouteEnd'>{endRegion}</p>
                    </div>
                </div>
            </div>
            <div className='confirmRouteButton'>
                <NavLink to="/SeatBooking" state={{tripId: id, routeId: routeId.id}}>Κράτησε μου μία θέση</NavLink>
            </div>
        </div>
    );
}

export default ConfirmRoute