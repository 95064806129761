import React from 'react';
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';

const SuccessfullReservation = () => {
    const navigate = useNavigate();

    const Ok = () => {
        navigate("/SearchRoute");
    }

    return(
        <div className='SuccessfullReservationMainDiv'>
            <h1>Η κράτηση της θέσης έγινε!</h1>
            <p>Επικοινωνήστε με τον οδηγό για λεπτομέριες
                Το τηλέφωνο του βρίσκεται στις λεπτομέριες της διαδρομής</p>
            <div className='SuccessfullButton'>
                <button onClick={Ok}>OK</button>
            </div>
        </div>
    );
}

export default SuccessfullReservation;