/*
 * Copyright 2021 Google LLC. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import * as ReactDom from "react-dom";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { createCustomEqual } from "fast-equals";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";
import { BrowserRouter, Route, NavLink, Link, Routes, useNavigate } from 'react-router-dom';
import x from '../../images/Homepage/x.png';
import axios from "axios";
import { json } from "stream/consumers";
import { passiveSupport } from 'passive-events-support/src/utils';
/*passiveSupport({
  debug: false,
  // add this one
  listeners: [
    {
      element: 'div',
      event: 'touchstart',
      prevented: true
    },
    {
      element: 'div',
      event: 'touchmove',
      prevented: true

    }
  ]
})*/


const render = (status: Status) => {
  return <h1>{status}</h1>;
};

const OfferRouteDestination = () => {
  const navigate = useNavigate();
  const [destination, setDestination] = React.useState(null);
  const [detail, setDetail] = React.useState(null);
  const [clicks, setClicks] = React.useState<google.maps.LatLng[]>([]);
  const [zoom, setZoom] = React.useState(3); // initial zoom
  const token = localStorage.getItem("token");
  const [regions, setRegions] = React.useState(null);
  const [center, setCenter] = React.useState<google.maps.LatLngLiteral>({
    lat: 40.63970373923698,
    lng: 22.944813740434537,
  });

    axios.get('https://api.thesscarpool.eu/api/Regions/GetAll', { headers: {"Authorization" : `Bearer ${token}`} })
    .then(res => {
      //console.log(res);
      sessionStorage.setItem("Regions", JSON.stringify(res.data));
      //console.log(JSON.parse(sessionStorage.getItem("")));
    })
    .catch(err => {
      console.log(err);
    });

    console.log(JSON.parse(sessionStorage.getItem("Regions")));

    const positions = JSON.parse(sessionStorage.getItem("Regions"));

    var newpositions = [];
    positions.$values.map( (item, i) => {
      newpositions[i] = {lat:parseFloat(item.lat), lng:parseFloat(item.lng), name:item.name}
  });


  //construct the marker
  const Marker: React.FC<google.maps.MarkerOptions> = (options) => {
    const [marker, setMarker] = React.useState<google.maps.Marker>();
    
  
    React.useEffect(() => {
      if (!marker) {
        setMarker(new google.maps.Marker());
      }
  
      // remove marker from map on unmount
      return () => {
        if (marker) {
          marker.setMap(null);
        }
      };
    }, [marker]);
  
    React.useEffect(() => {
      if (marker) {
        ["click", "idle"].forEach((eventName) =>
          google.maps.event.clearListeners(marker, eventName)
        );
        
        //add an event listener on click
        marker.addListener("click", () => {
          //console.log(marker.getTitle());

          //set the title of the marker in the afethria
          setDestination(marker.getTitle());
        });
        
      }
    }, [marker, onclick]);
    
  
  
    React.useEffect(() => {
      if (marker) {
        marker.setOptions(options);
      }
      
    }, [marker, options]);
  
    return null;
  };

  const confirmDestination = () => {
    if(destination != null){
      //console.log("confirmed start:  "+destination);
      sessionStorage.setItem("OfferDestination",destination);
      console.log(sessionStorage.getItem("destination"));
      //navigate("/CalendarStart");
        
        var postStart = sessionStorage.getItem("OfferStart");
        var postDestination = sessionStorage.getItem("OfferDestination");
        var details = "none";

        axios.get('https://api.thesscarpool.eu/api/Route/PostRoute/'+postStart+"/"+postDestination+"/"+details, { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res);
            axios.get('https://api.thesscarpool.eu/api/Route/GetMyRoutes', { headers: {"Authorization" : `Bearer ${token}`} })
            .then(res => {
                console.log(res.data);
                //setRoutes(routes => res.data);
                sessionStorage.setItem("MyRoutes",JSON.stringify(res.data));
            }).catch(err => {
                console.log(err);
            });
              setDetail(res.data);
              setTimeout(() => {
                  setDetail(null);
              },3000);
            //sessionStorage.setItem("searchDriveResults",JSON.stringify(res.data));
            navigate("/SuccessfullRouteAdd");
        }).catch(err => {
            console.log(err);
            setDetail(err);
            setTimeout(() => {
                setDetail(null);
            },3000);
        });

    }else{
      console.log("staaaaaaart");
    }
  }
 

  
  /*const onClick = (e: google.maps.Marker) => {
    //setClicks([...clicks, e.getPosition!]);
    
  }*/

  const onIdle = (m: google.maps.Map) => {
    console.log("onIdle");
    setZoom(m.getZoom()!);
    setCenter(m.getCenter()!.toJSON());
  };

  //console.log(Marker);


  return (
    <>
      <div style={{ display: "flex", height: "100%" }}>
        <NavLink to="/SearchRoute" className="x"></NavLink>
        <div className="mapsBegin">
          <p>Επιλέξτε μία πινέζα ως προορισμό</p>
          <ul>
            <li id="begin">Επιλογή: {destination}</li>
          </ul>
        </div>

      <Wrapper apiKey={'AIzaSyAFjzQisTG8TLolxVkCgV5zdMQm8Ql2fRg'} render={render}>
        <Map
          center={center}
          zoom={14}
          style={{ flexGrow: "1", height: "70%", position:"absolute", width:"100%", top:"15%", }}
        >
          {newpositions != null && newpositions.length > 0 ? newpositions.map( (pos, i) =>
            <Marker key={i} position={pos} clickable={true} title={pos.name}></Marker>
            ):''}
          {/*clicks.map((latLng, i) => (
            <Marker key={i} position={latLng} />
          ))*/}
        </Map>
      </Wrapper>
      {/* Basic form for controlling center and zoom of map. */}
    </div>
    <div className="MapsConfirmButton">
      <button onClick={confirmDestination}>Επιβεβαίωση</button>
    </div>
  </>
  );
};

interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  children?: React.ReactNode;
}

interface MarkerProps extends google.maps.MarkerOptions {
  style: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  children?: React.ReactNode;
}

const Map: React.FC<MapProps> = ({
  onClick,
  onIdle,
  children,
  style,
  ...options
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  React.useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};




const deepCompareEqualsForMaps = createCustomEqual(
  (deepEqual) => (a: any, b: any) => {
    if (
      isLatLngLiteral(a) ||
      a instanceof google.maps.LatLng ||
      isLatLngLiteral(b) ||
      b instanceof google.maps.LatLng
    ) {
      return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }

    // TODO extend to other types

    // use fast-equals for other objects
    return deepEqual(a, b);
  }
);

function useDeepCompareMemoize(value: any) {
  const ref = React.useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useDeepCompareEffectForMaps(
  callback: React.EffectCallback,
  dependencies: any[]
) {
  React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

/*window.addEventListener("DOMContentLoaded", () => {
  ReactDom.render(<Maps />, document.getElementById("root"));
});*/


export default OfferRouteDestination;
