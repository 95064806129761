import React from 'react';
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate, Navigate } from 'react-router-dom';
import logo from '../images/logo.png'
import axios from 'axios'
import greenDot from '../images/Homepage/green_dot.png'
import blueDot from '../images/Homepage/blue_dot.png'
import line from '../images/Homepage/line.png'
import MyRoutes from '../Homepages/MyRoutes';
import PendingHistoryRoutes from '../Homepages/PendingHistoryRoutes';

const HistoryRoutes = () => {
    const [activeCompleted, setActiveCompleted] = React.useState(true);
    const [activePending, setActivePending] = React.useState(false);
    const navigate = useNavigate();
    
    const token = localStorage.getItem("token");

    var routes = JSON.parse(sessionStorage.getItem("history")) || [];
    
    const Routes = () => {
        setActivePending(activePending => true);
        setActiveCompleted(activeCompleted => false);
        navigate("/MyRoutes");
    }

    const PendingRoutes = () => {
        setActiveCompleted(activeCompleted => true);
        setActivePending(activePending => false);
        navigate("/PendingHistoryRoutes");
    }
    
    return(
        <div className='HistoryRoutesMainDiv'>
            <NavLink className='back' to="/SearchRoute"></NavLink>
            <h1 className='HistoryRoutesH'>{window.location.pathname == "/MyRoutes" ? 'Οι διαδρομές που προσφέρω':'Τα δρομολόγια μου'}</h1>
            <div className='HistoryMainRoutes'>
                <button onClick={() => Routes()} style={{right:"10px", background: window.location.pathname == "/MyRoutes"  ? "#13CF97" : "#00AFCD",
                                                            color:"white",
                                                            opacity:window.location.pathname == "/MyRoutes" ? 1 : 0.5}}>Διαδρομές</button>
                <button onClick={() => PendingRoutes()} style={{left:"10px", background:  window.location.pathname == "/PendingHistoryRoutes" ? "#13CF97" : "#00AFCD",
                                                            color:"white",
                                                            opacity:window.location.pathname == "/PendingHistoryRoutes" ? 1 : 0.5}}>Δρομολόγια</button>
            </div>
            {window.location.pathname == "/MyRoutes" ? <MyRoutes /> : window.location.pathname == "/PendingHistoryRoutes" ? <PendingHistoryRoutes /> : ''}
        </div>
    );
}

export default HistoryRoutes;