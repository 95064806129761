import { CalendarComponent } from '@syncfusion/ej2-react-calendars';
import DatePicker from 'react-date-picker'
import Calendar from "react-calendar"
import React from 'react'
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate } from 'react-router-dom';

const PickDateBack = () => {
    const [date, setDate] = React.useState(new Date());
    const [time, setTime] = React.useState(null);
    const navigate = useNavigate();
    const locale = 'fr-CA'; 

    const onDateChange = (date) => {
        setDate(date);
    }

    const onTimeChange = () => {
        setTime(document.getElementById("time").value);
        
        sessionStorage.setItem("timeEnd",document.getElementById("time").value);
    }

    const confirmDate = () => {
        sessionStorage.setItem("dateEnd",new Intl.DateTimeFormat(
            locale, 
            {
              year: "numeric", 
              month: "2-digit", 
              day: "2-digit"
            }).format(date));
        
        navigate("/SearchRoute");
    }
    /*return(
        <CalendarComponent style={{width:"100%", display:"contents"}}/>
    );*/
    return(
        <div className='myCalendar'>
            <NavLink className='back' to="/SearchRoute"></NavLink>
            <h2>Πότε επιστρέφεις;</h2>
            <p>Επέλεξε ημερομηνία</p>
            <Calendar onChange={onDateChange} value={date} />
            <div className='timePicker'>
                <p>Επέλεξε ώρα</p>
                <input id="time" type="time" onChange={onTimeChange}></input>
            </div>
            <div className='confirmDate'>
                <button onClick={() => confirmDate()} defaultValue={sessionStorage.getItem("timeEnd") ? sessionStorage.getItem("timeEnd") : ''}>Ορισμός ημερομηνίας</button>
            </div>
        </div>  
    );
}

export default PickDateBack;