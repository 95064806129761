import OnBoardingOneBg from '../images/OnBoarding/OnBoardingOneBg.png';
import EllipseOne from '../images/OnBoarding/Ellipse125.png'
import EllipseTwo from '../images/OnBoarding/Ellipse126.png'
import Rectangle from '../images/OnBoarding/Rectangle82.png'

//libraries
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes } from 'react-router-dom';

const OnBoardingOne = () => {
    return(
        <div className='OnBoardingOne'>
            <NavLink className='OnBoardingOneNext' to="/OnBoardingTwo">Επόμενο</NavLink>
            <div className='OnBoardingOneDivOne'>
                <h5 className='OnBoardingOneH'>Κάνε τις διαδρομές σου με νέους φίλους</h5>
                <p className='OnBoardingOneP'>Βρείτε γείτονες από την περιοχή σας και μοιράσου τις διαδρομές  μαζί τους</p>
            </div>
            <div className='OnBoardingOneDivImg'>
                <img src={Rectangle}></img>
                <img src={EllipseOne}></img>
                <img src={EllipseTwo}></img>
            </div>
            <div className='OnBoardingOneDivLogin'>
                <p>Έχεις ήδη λογαριασμό; <NavLink to="/Login"> Συνδέσου εδώ.</NavLink></p> 
            </div>
        </div>
    );
}

export default OnBoardingOne;