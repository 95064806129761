//libraries
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate } from 'react-router-dom';
import axios from 'axios';
import React from 'react';

const ForgotPass = () => {
const [detail, setDetail] = React.useState(null);
const navigate = useNavigate();

    const sendMail = () => {

       var email = document.getElementById('mail').value;

       //console.log(email);
        axios.post(`https://api.thesscarpool.eu/api/Members/ForgotPassword?email=`+email)
          .then(res => {
            //console.log(res);
            navigate("/SendMailSuccess", {state:{mail:email}});
          }).catch(error => {
            //console.log(error);
            //setDetail(error);
            setTimeout(() => {
                setDetail(null);
            },3000);
          });
    }

    return(
        <div className='ForgotPassDivMain'>
            <NavLink className='WhiteBack' to="/Login"></NavLink>
            <div className='LoginDivSpace' style={{height:"250px"}}>

             </div>
            <div className='ForgotPassDivOne'>
                <h2 className='ForgotPassDivH'>Ξέχασες το password?</h2>
                <p className='ForgotPassDivP'>Εισάγετε το email σας και θα σας αποστείλουμε ένα link για επαναφορά κωδικού.</p>
            </div>
            <div className='ForgotPassDivCredentials'>
                <input id="mail" className='CredentialsUser' placeholder='Email' type='text'></input>
            </div>
            <div className='ForgotPassDivButton'>
                <button onClick={() => sendMail()}>Αποστολή</button>
            </div>
            <div className='SomethingWentWrong'>
                <p>{detail}</p>
            </div>
        </div>
    );
}

export default ForgotPass;