import React from 'react';
import nft from '../images/logo.png';
import axios from 'axios';
import termsInside from '../images/MyProfile/termsInside.png'


const Modal = ({ open, onClose }) => {
    const [terms, setTerms] = React.useState(null);

    React.useEffect(() => {
        axios.get("https://api.thesscarpool.eu/api/Members/GetPageContent?PageId=1")
        .then(res => {
            console.log(res.data);
            setTerms(res.data);
        })
        .catch(error => {
            console.log(error);
        });
    })

  if (!open) return null;
  return (
    <div onClick={onClose} className='overlay'>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className='modalContainer'
      >
            <p className='closeBtn' onClick={onClose}>
            X
          </p>
            <div className='TermsImageDiv' style={{background:"none", top:"40px"}}>
                    <img src={termsInside} style={{top:"10px"}}></img>
            </div>
            <div className='ProfileInfo' style={{top:"0", height:"520px"}}>
                <p style={{overflowY:"auto", overflowX:"hidden"}}
                dangerouslySetInnerHTML={{__html: terms}}
                ></p>
            </div>
        
      </div>
    </div>
  );
};

export default Modal;