import React from 'react';
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import logo from '../images/logo.png'

const SeatBooking = () => {
    const [seatOne, setSeatOne] = React.useState(false);
    const [seatTwo, setSeatTwo] = React.useState(false);
    const [seatThree, setSeatThree] = React.useState(false);
    const [seat, setSeat] = React.useState(null);
    const [response, setResponse] = React.useState(null);

    const token = localStorage.getItem("token");
    console.log(token);

    const navigate = useNavigate();
    const { handle } = useParams();
    const location = useLocation();
    console.log(location);
    const { tripId } = location.state;
    const { routeId } = location.state;
    console.log(tripId);
    console.log(routeId);

    const confirmDrive = () => {
        
        if(seatOne != false || seatTwo != false || seatThree != false){
            axios.get('https://api.thesscarpool.eu/api/TripReservation/Reservation/'+tripId+'/false/'+seat+'/'+routeId, { headers: {"Authorization" : `Bearer ${token}`} })
            .then(res => {
                console.log(res);
                if(res.status == 200){
                    setResponse(res.data);
                    setTimeout(() => {
                        navigate("/SuccessfullReservation");
                    },3000);
                }
            }).catch(err => {
                console.log(err);
                setResponse(err.response.data.detail);
            })
        }else{
            setResponse("Επιλέξτε θέση");
        }
          
    }

    const confirmSeatOne = () => {
        if(seatTwo != true && seatThree != true){
            setSeatOne(seatOne => !seatOne);
        }else{
            setSeatTwo(seatTwo => false);
            setSeatThree(seatThree => false);
            setSeatOne(seatOne => !seatOne);
        }
        setSeat(0);
        //console.log(seatOne);
    }

    const confirmSeatTwo = () => {

        if(seatOne != true && seatThree != true){
            setSeatTwo(seatTwo => !seatTwo);
        }else{
            setSeatTwo(seatTwo => !seatTwo);
            setSeatThree(seatThree => false);
            setSeatOne(seatOne => false);
        }
        
        setSeat(1);
        //console.log(seatTwo);
    }

    const confirmSeatThree = () => {

        if(seatOne != true && seatTwo != true){
            setSeatThree(seatThree => !seatThree);
        }else{
            setSeatTwo(seatTwo => false);
            setSeatThree(seatThree => !seatThree);
            setSeatOne(seatOne => false);
        }
        setSeat(2);
        //console.log(seatThree);
    }

    return(
        <div className='SeatBookingMainDiv'>
            <NavLink className='back' to="/SearchRoute"></NavLink>
            <h1>Διάλεξε την θέση σου</h1>
             <div className="seatsDiv">
                <div className='seatsButtons'>
                    <button onClick={confirmSeatOne} style={{left:"35px",position:"absolute", bottom:"20px",
                        backgroundColor : seatOne ? "#EB5757" : "#00AFCD"}}></button>
                    <button className='driverSeat' style={{right:"0px",position:"absolute", bottom:"20px"}}>
                    </button>
                    <button onClick={confirmSeatTwo} style={{left:"35px",position:"absolute",top:"40px",
                        backgroundColor : seatTwo ? "#EB5757" : "#00AFCD"}}></button>
                    <button onClick={confirmSeatThree} style={{right:"0px",position:"absolute",top:"40px",
                        backgroundColor : seatThree ? "#EB5757" : "#00AFCD"}}></button>
                </div>
            </div>
            <p className='reservationResponse'>{response}</p>
            <div className='SeatBookingButton'>
                <button onClick={confirmDrive}>Επιβεβαιώση Κράτησης</button>
            </div>
        </div>
    );
}

export default SeatBooking