import { CalendarComponent } from '@syncfusion/ej2-react-calendars';
import DatePicker from 'react-date-picker'
import Calendar from "react-calendar"
import React from 'react'
import axios from 'axios';
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate, useLocation } from 'react-router-dom';

const OfferRouteCalendarEnd = () => {
    const [date, setDate] = React.useState(new Date());
    const [time, setTime] = React.useState(null);
    const [detail, setDetail] = React.useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const locale = 'fr-CA';
    const token = localStorage.getItem("token");
    
    const {routeId} = location.state;
    const {start} = location.state;
    const {end} = location.state;
    const {details} = location.state;

    const onDateChange = (date) => {
        setDate(date);
    }

    const onTimeChange = () => {
        setTime(document.getElementById("time").value);
        
        sessionStorage.setItem("OfferRouteCalendarEndTime",document.getElementById("time").value);
     
    }

    const confirmDate = () => {
        sessionStorage.setItem("OfferRouteCalendarEndDate",new Intl.DateTimeFormat(
            locale, 
            {
              year: "numeric", 
              month: "2-digit", 
              day: "2-digit"
            }).format(date));
        
      
            var postDate = sessionStorage.getItem("OfferRouteCalendarStartDate");
            var endDate = sessionStorage.getItem("OfferRouteCalendarEndDate");
            var startTime = sessionStorage.getItem("OfferRouteCalendarStartTime");
            var endTime = sessionStorage.getItem("OfferRouteCalendarEndTime");

            var startHours = startTime.slice(0,-3);
            var endHours = endTime.slice(0,-3);

            var startMins = startTime.slice(3);
            var endMins = endTime.slice(3);


            axios.get('https://api.thesscarpool.eu/api/Trips/PostTrip/'
            +routeId+"/"+postDate+"/"+endDate+"/"+startHours+"/"+endHours+"/"+startMins+"/"+endMins, 
            { headers: {"Authorization" : `Bearer ${token}`} })
            .then(res => {
                console.log(res);
                setDetail(res.data);
                setTimeout(() => {
                    setDetail(null);
                },3000);
                //sessionStorage.setItem("searchDriveResults",JSON.stringify(res.data));
                navigate("/SuccessfullTripAdd");
            }).catch(err => {
                console.log(err);
            });
    }
    /*return(
        <CalendarComponent style={{width:"100%", display:"contents"}}/>
    );*/
    return(
        <div className='myCalendar'>
            <NavLink className='back' to="/MyRoutes"></NavLink>
            <h2>Πότε επιστρέφεις;</h2>
            <Calendar onChange={onDateChange} value={date} />
            <div className='timePicker'>
                <input id="time" type="time" onChange={() => onTimeChange()}></input>
            </div>
            <div className='SomethingWentWrongCalendar'>
                {detail}
            </div>
            <div className='confirmDate'>
                <button onClick={() => confirmDate()}>Ορισμός ημερομηνίας</button>
            </div>
        </div>  
    );
}

export default OfferRouteCalendarEnd;