import React from 'react';
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate, useLocation, useParams } from 'react-router-dom';
import axios, { CanceledError } from 'axios';

const DeleteRoute = () => {
    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    const location = useLocation();

    const {routeId} = location.state;

    const Ok = (routeId) => {
        axios.get('https://api.thesscarpool.eu/api/Route/DeleteRoute/'+routeId, { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res);
            //setRoutes(routes => res.data);
            //sessionStorage.setItem("TripByRouteId",JSON.stringify(res.data));
            navigate("/SuccessfullRouteDelete");
        }).catch(err => {
            console.log(err);
        });
    }

    const Cancel = () => {
        navigate("/MyRoutes")
    }

    return(
        <div className='SuccessfullReservationMainDiv'>
            <h1>Είστε σίγουροι ότι θέλετε να διαγράψετε την διαδρομή σας;</h1>
            <div className='SuccessfullButton'>
                <button style={{right:"10px"}} onClick={() => Ok(routeId)}>Ναι</button>
                <button style={{left:"10px"}} onClick={() => Cancel()}>Ακύρωση</button>
            </div>
        </div>
    );
}

export default DeleteRoute;