import React from 'react';
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate, useLocation, useParams } from 'react-router-dom';
import axios, { CanceledError } from 'axios';

const DeleteFriends = () => {
    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    const location = useLocation();

    const {id} = location.state;
    const {name} = location.state;

    const Ok = (id) => {
        axios.get('https://api.thesscarpool.eu/api/Members/DeleteFriend/'+id, { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res);
            //sessionStorage.setItem("FriendsIds",JSON.stringify(res.data.isFriendWith));
            //forceUpdate();
            navigate("/ChatList");
        })
        .catch(err => {
            console.log(err);
        });
    }

    const Cancel = () => {
        navigate("/ChatList")
    }

    return(
        <div className='SuccessfullReservationMainDiv'>
            <h1>Είστε σίγουροι ότι θέλετε να διαγράψετε τον χρήστη {name} από τους φίλους σας?</h1>
            <div className='SuccessfullButton'>
                <button style={{right:"10px"}} onClick={() => Ok(id)}>Ναι</button>
                <button style={{left:"10px"}} onClick={() => Cancel()}>Ακύρωση</button>
            </div>
        </div>
    );
}

export default DeleteFriends;