import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate, Navigate } from 'react-router-dom';
import React from 'react';
import axios from 'axios';

const ChatList = () => {
    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    const [_, forceUpdate] = React.useReducer((x) => 1, 0);

    React.useEffect( () => {
        axios.get('https://api.thesscarpool.eu/api/Members/GetFriends', { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res);
            console.log(res.data.hasFriends.$values.length);
            console.log(res.data.isFriendWith.$values.length);
            if(res.data.isFriendWith.$values.length > 0){
                sessionStorage.setItem("FriendsIds",JSON.stringify(res.data.isFriendWith));
            }

            if(res.data.hasFriends.$values.length > 0){
                sessionStorage.setItem("FriendsIds",JSON.stringify(res.data.hasFriends));
            }
            
            forceUpdate();
        })
        .catch(err => {
            console.log(err);
        });
    });

    var friends = JSON.parse(sessionStorage.getItem("FriendsIds"));
    //console.log(friends);

    const DeleteFriend = (id, firstName) => {
        navigate("/DeleteFriends", {state:{
            id:id,
            name:firstName
        }});
    }

    return(
        <div className="ChatList">
            <NavLink className='back' to="/SearchRoute"></NavLink>
            <h1>Φίλοι</h1>
            <div className="NotificationsDiv" style={{marginTop:"100px"}}>
                {friends != null && friends.$values.length && friends.$values.length > 0 ? friends.$values.map( (item) => (

                    <div key={item.id} className="Notification">
                        <div className='NotificationsProfileImage' style={{backgroundImage: item.photoURL != null ? 
                    "url(https://admin.thesscarpool.eu/"+item.photoURL+")":''}}></div>
                        <div className='NotificationsPassengerInfo'>
                            <p>{item.firstName + " " +item.lastName}</p>
                            <p style={{top:"-10px", position:"relative"}}>{item.phoneNumber}</p>
                        </div>
                        <div className='NotificationActions'>
                            {/*<button className='tick' style={{right:"10px"}} onClick={() => Accept(item.passengerId, item.tripId, item.passengerFirstName)}>Αποδοχή</button>*/}
                            <button className='xbutton' onClick={() => DeleteFriend(item.id, item.firstName)}>Διαγραφή</button>
                        </div>
                    </div>
                )):''}
                </div>
        </div>
    );
}

export default ChatList;