import React from 'react';
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate } from 'react-router-dom';
import logo from '../images/logo.png'
import axios from 'axios'
import prof from '../images/MyProfile/Profile.png'

const DriverProfile = () => {
    const [_, forceUpdate] = React.useReducer((x) => 1, 0);
    const token = localStorage.getItem("token");
    const navigate = useNavigate();

    React.useEffect( () => {
        axios.get('https://api.thesscarpool.eu/api/Driver/GetDriverProfile', { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res);
            forceUpdate();
            //setRoutes(routes => res.data);
            sessionStorage.setItem("DriverProfile",JSON.stringify(res.data));
        }).catch(err => {
            console.log(err);
        });
    });

    const profile = JSON.parse(sessionStorage.getItem("DriverProfile")) || null;


    const UpdateDriver = () => {
        var modelName = document.getElementById('modelName').value;
        var color = document.getElementById('color').value;
        var plateNumber = document.getElementById('plateNumber').value;
        var DriversLicence = document.getElementById('DriversLicence').checked;

        axios.get('https://api.thesscarpool.eu/api/Driver/UpdateDriverProfile/'+modelName+'/'+color+'/'+plateNumber+"/"+DriversLicence, { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res);
            if(res.status == 200){
                navigate("/SuccessfullUserUpdate");
            }
            //setRoutes(routes => res.data);
            //sessionStorage.setItem("DriverProfile",JSON.stringify(res.data));
        }).catch(err => {
            console.log(err);
        });
    }
    
    return(
        <div className='MyProfile'>
            <NavLink className='back' to="/Profile" style={{top:"20px"}}></NavLink>
            <h1 className='MyProfileH'>Προφίλ οδηγού</h1>
            <div className='ProfileInfo'>
                <label style={{top:"10px"}}>Μοντέλο</label>
                <input id="modelName" name="FirstName" type="text" defaultValue={profile != null && profile.$values.length && profile.$values.length > 0 ? 
                    profile.$values[0].carModelName : ''}></input>

                <label style={{top:"10px"}}>Χρώμα</label>
                <input id ="color" type="text" defaultValue={profile != null && profile.$values.length && profile.$values.length > 0  ? 
                    profile.$values[0].carColor: ''}></input>

                <label style={{top:"10px"}}>Περιγραφή π.χ.(Αριθμός πινακίδας)</label>
                <input id="plateNumber" type="text" defaultValue={profile != null && profile.$values.length && profile.$values.length > 0  ? 
                    profile.$values[0].carDescription: ''}></input>

                <div style={{top:"5px"}} className='RememberMeCheck'>
                    <input className='driversLicenceInput' type='checkbox' id="DriversLicence" defaultChecked={profile != null && profile.$values.length && profile.$values.length > 0  ? 
                    profile.$values[0].hasDriverLicence : false}></input>
                    <p style={{top:"10px"}}>Δηλώνω υπεύθυνα οτι έχω δίπλωμα οδήγησης</p>
                </div>
            </div>
            <div className='UpdateDivButton'>
                <button onClick={UpdateDriver}>Ενημέρωση</button>
            </div>
        </div>
    );
}

export default DriverProfile