import React from 'react';
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate, useLocation, useParams } from 'react-router-dom';
import axios, { CanceledError } from 'axios';

const DeleteReservation = () => {
    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    const location = useLocation();

    const {tripId} = location.state;
    console.log(tripId);

    const Ok = (tripId) => {
        document.getElementById('loader').style.display = "inline-block";
        axios.get('https://api.thesscarpool.eu/api/TripReservation/DeleteReservation/'+tripId, { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res);
            axios.get('https://api.thesscarpool.eu/api/Members/GetReservationsHistory', { headers: {"Authorization" : `Bearer ${token}`} })
            .then(res => {
                console.log(res.data);
                //setRoutes(routes => res.data);
                sessionStorage.setItem("PendingHistory",JSON.stringify(res.data));
                navigate("/SuccessfullReservationDelete");
            }).catch(err => {
                console.log(err);
                //document.getElementById('loader').style.display = "none";
            });
        }).catch(err => {
            console.log(err);
            document.getElementById('loader').style.display = "none";
            alert(err);
        });
    }

    const Cancel = () => {
        navigate("/PendingHistoryRoutes")
    }

    return(
        <div className='SuccessfullReservationMainDiv'>
            <h1>Είστε σίγουροι ότι θέλετε να διαγράψετε τo δρομολόγιο σας;</h1>
            <div id="loader" className="loader"></div>
            <div className='SuccessfullButton'>
                <button style={{right:"10px"}} onClick={() => Ok(tripId)}>Ναι</button>
                <button style={{left:"10px"}} onClick={() => Cancel()}>Ακύρωση</button>
            </div>
        </div>
    );
}

export default DeleteReservation;