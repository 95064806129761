import { CalendarComponent } from '@syncfusion/ej2-react-calendars';
import DatePicker from 'react-date-picker'
import Calendar from "react-calendar"
import React from 'react'
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';

const OfferPickDateNewStart = () => {
    const [date, setDate] = React.useState(new Date());
    const [time, setTime] = React.useState(null);
    const navigate = useNavigate();
    const locale = 'fr-CA'; 

    const token = localStorage.getItem("token");

    React.useEffect( () => {
    axios.get('https://api.thesscarpool.eu/Regions/GetAll', { headers: {"Authorization" : `Bearer ${token}`} })
    .then(res => {
        console.log(res);
    })
    .catch(err => {
        console.log(err);
    })
    });

    const location = useLocation();

    const {routeId} = location.state;
    const {start} = location.state;
    const {end} = location.state;
    const {details} = location.state;

    sessionStorage.setItem("routeId",routeId);
    sessionStorage.setItem("start",start);
    sessionStorage.setItem("end",end);
    sessionStorage.setItem("details",details);

    const onDateChange = (date) => {
        setDate(date);
    }

    const onTimeChange = () => {
        setTime(document.getElementById("time").value);
        console.log(document.getElementById("time").value);
        sessionStorage.setItem("OfferTime",document.getElementById("time").value);
        console.log(sessionStorage.getItem("OfferTime"));
    }

    const confirmDate = () => {
        //sessionStorage.setItem("OfferRouteDate",date.toISOString());
        sessionStorage.setItem("OfferRouteDateNewStart",new Intl.DateTimeFormat(
            locale, 
            {
              year: "numeric", 
              month: "2-digit", 
              day: "2-digit"
            }).format(date));
        navigate("/CalendarEnd");
    }
    /*return(
        <CalendarComponent style={{width:"100%", display:"contents"}}/>
    );*/
    return(
        <div className='myCalendar'>
            <NavLink className='back' to="/MyRoutes"></NavLink>
            <h2>Πότε αναχωρείς;</h2>
            <p>Επέλεξε ημερομηνία</p>
            <Calendar onChange={onDateChange} value={date} />
            <div className='timePicker'>
                <p>Επέλεξε ώρα</p>  
                <input id="time" type="time" onChange={onTimeChange}></input>
            </div>
            <div className='confirmDate'>
                <button onClick={confirmDate}>Ορισμός ημερομηνίας</button>
            </div>
        </div>  
    );
}

export default OfferPickDateNewStart;