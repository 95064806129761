import React from 'react';
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';

const SuccessfullUserUpdate = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem("token");

    axios.get('https://api.thesscarpool.eu/api/Members/GetMemberProfile', { headers: {"Authorization" : `Bearer ${token}`} })
    .then(res => {
        console.log(res);
        //setRoutes(routes => res.data);
        sessionStorage.setItem("MyProfile",JSON.stringify(res.data));
    }).catch(err => {
        console.log(err);
    });

    const Ok = () => {
        navigate("/SearchRoute");
    }

    return(
        <div className='SuccessfullReservationMainDiv'>
            <h1>Το προφίλ σας ενημερώθηκε με επιτυχία!</h1>
            <div className='SuccessfullButton'>
                <button onClick={Ok}>OK</button>
            </div>
        </div>
    );
}

export default SuccessfullUserUpdate;