import React from 'react';
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate, useLocation } from 'react-router-dom';
import logo from '../images/logo.png'
import axios from 'axios'
import greenDot from '../images/Homepage/green_dot.png'
import blueDot from '../images/Homepage/blue_dot.png'
import line from '../images/Homepage/line.png'
import edit from '../images/Homepage/pencil.png'

const EditRoute = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const [_, forceUpdate] = React.useReducer((x) => 1, 0);



    const {start} = location.state;
    const {end} = location.state;
    const {details} = location.state;
    const {routeId} = location.state;

    React.useEffect( () => {
        axios.get('https://api.thesscarpool.eu/api/Route/GetRouteById/'+routeId, { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res);
            //setRoutes(routes => res.data);
            sessionStorage.setItem("Routes",JSON.stringify(res.data));
            forceUpdate();
        }).catch(err => {
            console.log(err);
        });
    });
    
    const UpdateRoute = () => {
        var newStart = document.getElementById("start").value;
        var newEnd = document.getElementById("end").value;
        var newDetails = document.getElementById("details").value;

        console.log(newDetails);

        axios.get('https://api.thesscarpool.eu/api/Route/UpdateRoute/'+routeId+"/"+newStart+"/"+newEnd+"/"+newDetails, { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res);
            //setRoutes(routes => res.data);
            //sessionStorage.setItem("TripByRouteId",JSON.stringify(res.data));
            //navigate("/SuccessfullRouteDelete");
            if(res.data == 'Updated'){
                navigate("/SuccessfullRouteUpdate");
            }
        }).catch(err => {
            console.log(err);
        });
    }


    var dates = JSON.parse(sessionStorage.getItem("Routes")) || null;
    console.log(dates);

    return(
        <div className="EditRoutes">
            <NavLink className='back' to="/MyRoutes"></NavLink>
            <div className='HistoryRoutesDiv' style={{top:"40px"}}>
                <div className='EditRoutesDivRegions'>
                    <div className='HistoryRouteInfo'>
                        <label style={{top:"10px"}}>Αφετηρία</label>
                        <input id="start" name="FirstName" type="text" defaultValue={dates != null && dates.$values.length && dates.$values.length > 0 ? 
                        dates.$values[0].startRegion.name : ''}></input>

                        <label style={{top:"10px"}}>Προορισμός</label>
                        <input id ="end" type="text" defaultValue={dates != null && dates.$values.length && dates.$values.length > 0 ?
                        dates.$values[0].endRegion.name: ''}></input>

                        <label style={{top:"10px"}}>Λεπτομέρειες</label>
                        <textarea id="details" defaultValue={dates != null && dates.$values.length && dates.$values.length > 0 ? 
                        dates.$values[0].routeDetails:''}></textarea>
                    </div>
                </div>
                
            </div>
            <div className="UpdateRoute">
                <button onClick={() => UpdateRoute()}>Ενημέρωση</button>
            </div>
        </div>
    );
}

export default EditRoute