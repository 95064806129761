import React from 'react';
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate } from 'react-router-dom';
import axios from 'axios'
import prof from '../images/MyProfile/Profile.png'

const Notifications = () => {
    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    const [_, forceUpdate] = React.useReducer((x) => 1, 0);

    React.useEffect( () => {
        axios.get('https://api.thesscarpool.eu/api/TripReservation/NotificationForReservation', { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res);
            sessionStorage.setItem("Notifications",JSON.stringify(res.data));
            forceUpdate();
        })
        .catch(err => {
            console.log(err);
        });
    });

    const Accept = (passengerId, tripId, passengerFirstName) => {
        axios.get('https://api.thesscarpool.eu/api/TripReservation/AcceptPassenger/'+passengerId+"/"+tripId, { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res);
            //sessionStorage.setItem("Notifications",JSON.stringify(res.data));
            navigate("/SuccessfullConfirmation", {state:{
                name: passengerFirstName
            }});
        })
        .catch(err => {
            console.log(err);
        });
    }

    const Reject = (passengerId, tripId, passengerFirstName) => {
        axios.get('https://api.thesscarpool.eu/api/TripReservation/RejectPassenger/'+passengerId+"/"+tripId, { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res);
            //sessionStorage.setItem("Notifications",JSON.stringify(res.data));
            navigate("/SuccessfullConfirmation", {state:{
                name: passengerFirstName
            }});
        })
        .catch(err => {
            console.log(err);
        });
    }

    var notifications = JSON.parse(sessionStorage.getItem("Notifications")) || null;

    return(
        <div className="NotificationsMainDiv">
            <NavLink className='back' to="/SearchRoute"></NavLink>
            <h1>Ειδοποιήσεις</h1>
            <div className="NotificationsDiv">
                {notifications != null && notifications.$values.length && notifications.$values.length > 0 ? notifications.$values.map( (item) => (

                    <div className="Notification">
                        <div className='NotificationsProfileImage'  style={{backgroundImage:"url(https://admin.thesscarpool.eu/"+item.passengerPhotoUrl+")"}}></div>
                        <div className='NotificationsPassengerInfo'>
                            <p>Ο χρήστης {item.passengerFirstName+ " " +item.passengerLastName}</p>
                            <p style={{top:"-10px", position:"relative"}}>Θέλει να κάνει κράτηση στο δρομολόγιο σας.</p>
                            <p style={{top:"-15px", position:"relative"}}>{item.startRegionName +" - "+item.endRegionName+" "+item.actualStartDate}</p>
                        </div>
                        <div className='NotificationActions'>
                            <button className='tick' style={{right:"10px"}} onClick={() => Accept(item.passengerId, item.tripId, item.passengerFirstName)}>Αποδοχή</button>
                            <button className='xbutton' style={{left:"10px"}} onClick={() => Reject(item.passengerId, item.tripId, item.passengerFirstName)}>Απόρριψη</button>
                        </div>
                    </div>
                )):''}
            </div>
        </div>
    );
}

export default Notifications