import React from 'react';
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';

const SuccessfullRouteAdd = () => {
    const navigate = useNavigate();

    const Ok = () => {
        navigate("/MyRoutes");
    }

    return(
        <div className='SuccessfullReservationMainDiv'>
            <h1>Η διαδρομή σας καταχωρήθηκε με επιτυχία!</h1>
            
            <div className='SuccessfullButton'>
                <button onClick={Ok}>OK</button>
            </div>
        </div>
    );
}

export default SuccessfullRouteAdd;