import { CalendarComponent } from '@syncfusion/ej2-react-calendars';
import DatePicker from 'react-date-picker'
import Calendar from "react-calendar"
import React from 'react'
import axios from 'axios';
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate, useLocation, useParams } from 'react-router-dom';

const OfferPickDateNewEnd = () => {
    const [date, setDate] = React.useState(new Date());
    const [time, setTime] = React.useState(null);
    const navigate = useNavigate();
    const locale = 'fr-CA'; 
    const token = localStorage.getItem("token");
    const [detail, setDetail] = React.useState(null);


React.useEffect( () => {
  axios.get('https://api.thesscarpool.eu/Regions/GetAll', { headers: {"Authorization" : `Bearer ${token}`} })
  .then(res => {
    console.log(res);
  })
  .catch(err => {
    console.log(err);
  })
});



    const onDateChange = (date) => {
        setDate(date);
    }

    const onTimeChange = () => {
        setTime(document.getElementById("time").value);
        console.log(document.getElementById("time").value);
        sessionStorage.setItem("OfferTime",document.getElementById("time").value);
        console.log(sessionStorage.getItem("OfferTime"));
    }

    const confirmDate = () => {
        //sessionStorage.setItem("OfferRouteDate",date.toISOString());
        sessionStorage.setItem("CalendarEndDate",new Intl.DateTimeFormat(
            locale, 
            {
              year: "numeric", 
              month: "2-digit", 
              day: "2-digit"
            }).format(date));

            var routeId = sessionStorage.getItem("routeId");
            var postDate = sessionStorage.getItem("OfferRouteDateNewStart");
            var endDate = sessionStorage.getItem("CalendarEndDate");
            var postStart = sessionStorage.getItem("start");
            var postDestination = sessionStorage.getItem("end");
            var details = sessionStorage.getItem("details");
            var startTime = sessionStorage.getItem("time");
            var endTime = sessionStorage.getItem("timeEnd");

            axios.get('https://api.thesscarpool.eu/api/Trips/PostTrip/'+routeId+"/"+postDate+"/"+endDate+"/"+startTime+"/"+endTime, { headers: {"Authorization" : `Bearer ${token}`} })
            .then(res => {
                console.log(res);
                setDetail(res.data);
                setTimeout(() => {
                    setDetail(null);
                },3000);
                //sessionStorage.setItem("searchDriveResults",JSON.stringify(res.data));
                //navigate("/RouteResults");
            }).catch(err => {
                console.log(err);
            });
        //navigate("/OfferRoute");
    }
    /*return(
        <CalendarComponent style={{width:"100%", display:"contents"}}/>
    );*/
    return(
        <div className='myCalendar'>
            <NavLink className='back' to="/Calendar"></NavLink>
            <h2>Πότε Επιστρέφεις;</h2>
            <p>Επέλεξε ημερομηνία</p>
            <Calendar onChange={onDateChange} value={date} />
            <div className='timePicker'>
                <p>Επέλεξε ώρα</p>
                <input id="time" type="time" onChange={onTimeChange}></input>
            </div>
            <div className='SomethingWentWrongCalendar'>
                <p>{detail}</p>
            </div>
            <div className='confirmDate'>
                <button onClick={confirmDate}>Ορισμός ημερομηνίας</button>
            </div>
        </div>  
    );
}

export default OfferPickDateNewEnd;