//libraries
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate } from 'react-router-dom';
import axios from 'axios';
import React from 'react';
import glass from '../../images/Homepage/search.png';
import greenDot from '../../images/Homepage/green_dot.png'
import blueDot from '../../images/Homepage/blue_dot.png'
import line from '../../images/Homepage/line.png'
import logo from '../../images/logo.png'

//files
import calendar from '../../images/Homepage/CombinedShape.png'
import passenger from '../../images/Homepage/Union.png'
import { act } from 'react-dom/test-utils';



const SearchRoute = () => {
    const start = sessionStorage.getItem("start");
    const destination = sessionStorage.getItem("destination");
    const [response,setResponse] = React.useState(null);
    const token = localStorage.getItem("token");
    console.log(token);
    
    const navigate = useNavigate();

    React.useEffect( () => {
        axios.get('https://api.thesscarpool.eu/api/TripReservation/NotificationForReservation', { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res);
            sessionStorage.setItem("Notifications",JSON.stringify(res.data));
        })
        .catch(err => {
            console.log(err);
        });

        axios.get('https://api.thesscarpool.eu/api/Members/GetReservationsHistory', { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res.data);
            //setRoutes(routes => res.data);
            sessionStorage.setItem("PendingHistory",JSON.stringify(res.data));
        }).catch(err => {
            console.log(err);
        });

        axios.get('https://api.thesscarpool.eu/api/Route/GetMyRoutes', { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res.data);
            //setRoutes(routes => res.data);
            sessionStorage.setItem("MyRoutes",JSON.stringify(res.data));
        }).catch(err => {
            console.log(err);
        });

        axios.get('https://api.thesscarpool.eu/api/Members/GetMemberProfile', { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res.data);
            //setRoutes(routes => res.data);
            sessionStorage.setItem("MyProfile",JSON.stringify(res.data));
        }).catch(err => {
            console.log(err);
        });

        axios.get('https://api.thesscarpool.eu/api/Regions/GetAll', { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            //console.log(res);
            sessionStorage.setItem("Regions", JSON.stringify(res.data));
            //console.log(JSON.parse(sessionStorage.getItem("")));
        })
        .catch(err => {
            console.log(err);
        });

        axios.get('https://api.thesscarpool.eu/api/Members/GetReservationsHistory', { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res.data);
            //setRoutes(routes => res.data);
            sessionStorage.setItem("PendingHistory",JSON.stringify(res.data));
        }).catch(err => {
            console.log(err);
        });

        axios.get('https://api.thesscarpool.eu/api/Trips/GetMyTrips', { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res.data);
           
            //setRoutes(routes => res.data);
            sessionStorage.setItem("MyTrips",JSON.stringify(res.data));
        }).catch(err => {
            console.log(err);
        });

        axios.get('https://api.thesscarpool.eu/api/Members/GetFriends', { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res);
            //console.log(res.data.hasFriends.$values.length);
            //console.log(res.data.isFriendWith.$values.length);
            if(res.data.isFriendWith.$values.length > 0){
                sessionStorage.setItem("FriendsIds",JSON.stringify(res.data.isFriendWith));
            }

            if(res.data.hasFriends.$values.length > 0){
                sessionStorage.setItem("FriendsIds",JSON.stringify(res.data.hasFriends));
            }
        })
        .catch(err => {
            console.log(err);
        });

        axios.get('https://api.thesscarpool.eu/api/TripReservation/NotificationForReservation', { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res);
            sessionStorage.setItem("Notifications",JSON.stringify(res.data));
        })
        .catch(err => {
            console.log(err);
        });
    });

    const SearchDrive = () => {
        var date = sessionStorage.getItem("date");
        var endDate = sessionStorage.getItem("dateEnd");
        var start = sessionStorage.getItem("start");
        var end = sessionStorage.getItem("destination");
        var startTime = sessionStorage.getItem("time");
        var endTime = sessionStorage.getItem("timeEnd");
        document.getElementById('loader').style.display = "inline-block";

        if(date != null && endDate != null && start != null && end != null && startTime != null && endTime != null){
            var startHours = startTime.slice(0,-3);
            var startMins = startTime.slice(3);

            var endHours = endTime.slice(0,-3);
            var endMins = endTime.slice(3);

            axios.get('https://api.thesscarpool.eu/api/Trips/GetAll/'
            +date+"/"+endDate+"/"+start+"/"+end+"/"+startHours+"/"+startMins+"/"+endHours+"/"+endMins,
            { headers: {"Authorization" : `Bearer ${token}`} })
            .then(res => {
                //console.log(res.data);
                sessionStorage.setItem("searchDriveResults",JSON.stringify(res.data));
                navigate("/RouteResults");
            }).catch(err => {
                //console.log(err.response.data.detail);
                setResponse(err.response.data.detail);
                document.getElementById('loader').style.display = "none";
                setTimeout(() => {
                    setResponse(null);
                },3000);
            });
        }else{
            setResponse("Δεν συμπληρώσατε όλα τα απαραίτητα πεδία");
            setTimeout(() => {
                setResponse(null);
            },3000);
            document.getElementById('loader').style.display = "none";
        }
    }
    
    return (
        <>
        <div className='StartDestination'>
                <div className='HomepageRoutesDiv'>
                    <div className='HomepageRoutesDivImages'>
                        <img src={greenDot} style={{left:"-1px",position:"relative"}}></img>
                        <img src={line} style={{height:"40px",top:"5px",position:"relative"}}></img>
                        <img src={blueDot} style={{position:"relative",top:"10px",left:"-3px"}}></img>
                        
                    </div>
                    <div className='HomepageRoutesDivRegions'>
                        <NavLink to="/Start">Αφετηρία</NavLink>
                        <p onClick={() => navigate("/Start")}>{start != null ? start : 'Επιλέξτε Αφετηρία'}</p>
                        <hr></hr>
                        <NavLink to="/Destination">Προορισμός</NavLink>
                        <p onClick={() => navigate("/Destination")}>{destination != null ? destination: 'Επιλέξτε Προορισμό'}</p>
                    </div>
                </div>
                
            </div>
            <div className='NoTripsFound'>
                <p>{response}</p>
            </div>
            <div id="loader" className="loader"></div>
            <div className='blackEclipse'>
                <div className='HomepageReservation'>
                    <div onClick={() =>  navigate("/Calendar")} className='HomepageCalendar' style={{right:"5px"}}>
                        <img src={calendar}></img>
                        <div className='date'>
                            <p className='day'>{sessionStorage.getItem("date") != null ? sessionStorage.getItem("date") : 'Σήμερα'}</p>
                            <p className='hour'>{sessionStorage.getItem("time") != null ? sessionStorage.getItem("time") : '8:00'}</p>
                        </div>
                    </div>
                    
                    <div onClick={() =>  navigate("/CalendarReturn")} className='HomepageCalendar' style={{left:"5px"}}>
                        <img src={calendar}></img>
                        <div className='date'>
                            <p className='day'>{sessionStorage.getItem("dateEnd") != null ? sessionStorage.getItem("dateEnd") : 'Σήμερα'}</p>
                            <p className='hour'>{sessionStorage.getItem("timeEnd") != null ? sessionStorage.getItem("timeEnd") : '8:00'}</p>
                        </div>
                    </div>
                </div>
                <div className='searchButtonDiv'>
                    <button onClick={() => SearchDrive()}>Αναζήτησε διαδρομή</button>
                </div>
                <div className='menuLogo'>
                    <img src={logo}></img>
                </div>
                <div className='menu'>
                    <NavLink to="/PendingHistoryRoutes" className='car'></NavLink>
                    <NavLink to="/ChatList" className='messages'></NavLink>
                    <NavLink to="/Notifications" className='notifications'></NavLink>
                    <NavLink to="/Profile" className='profile'></NavLink>
                </div>
            </div>
        </>
    );
}

export default SearchRoute;