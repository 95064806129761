import React from 'react';
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate } from 'react-router-dom';
import logo from '../images/logo.png'
import axios from 'axios'
import greenDot from '../images/Homepage/green_dot.png'
import blueDot from '../images/Homepage/blue_dot.png'
import line from '../images/Homepage/line.png'


const PendingHistoryRoutes = () => {
    const [_, forceUpdate] = React.useReducer((x) => 1, 0);
    const token = localStorage.getItem("token");


    React.useEffect(() => {
        axios.get('https://api.thesscarpool.eu/api/Members/GetReservationsHistory', { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res.data);
            forceUpdate();
            //setRoutes(routes => res.data);
            sessionStorage.setItem("PendingHistory",JSON.stringify(res.data));
        }).catch(err => {
            console.log(err);
        });

        axios.get('https://api.thesscarpool.eu/api/Trips/GetMyTrips', { headers: {"Authorization" : `Bearer ${token}`} })
        .then(res => {
            console.log(res.data);
            forceUpdate();
            //setRoutes(routes => res.data);
            sessionStorage.setItem("MyTrips",JSON.stringify(res.data));
        }).catch(err => {
            console.log(err);
        });
        forceUpdate();
    });

    

    var routes = JSON.parse(sessionStorage.getItem("PendingHistory")) || null;
    var MyTrips = JSON.parse(sessionStorage.getItem("MyTrips")) || null;

        //console.log(routes);
        return(
            <div className='History'>
                <h1 className='PendingHistoryRoutesH'>Τρέχοντα</h1>
                {routes != null && routes.$values.length && routes.$values.length > 0 ? routes.$values.map( (item) => (
                    item.confirmedByDriver != false && item.status == 0 ?
                    <div key={item.id} className="HistoryRoutes">
                        
                        {item.status == 0 ? <NavLink style={{textDecoration:"none",
                                                            width: "100%",
                                                            height: "100%",
                                                            position: "absolute",
                                                            left: "0",
                                                            zIndex:"999"}} 
                                    key={item.id} to="/PassengerRideInfo"         
                                    state={{
                                        id:item.id,
                                        tripId : item.tripId,
                                        driverId: item.offeredByDriverId,
                                        passengerId: item.passengerId,
                                        vehicle: item.vehicleModel,
                                        driverFirstName: item.driverFirstName,
                                        driverLastName: item.driverLastName,
                                        startRegionName: item.startRegionName,
                                        endRegionName:item.endRegionName,
                                        startDate:item.actualStartDate,
                                        endDate:item.actualEndDate

                                    }}
                            ></NavLink>:''}
                            <div className='HistoryRoutesDiv'>
                                <div className='HistoryRoutesDivImages'>
                                    <img src={greenDot} style={{left:"-1px",position:"relative"}}></img>
                                    <img src={line} style={{height:"10px",top:"5px",position:"relative"}}></img>
                                    <img src={blueDot} style={{position:"relative",top:"10px",left:"-3px"}}></img>
                                </div>
                                <div className='HistoryRoutesDivRegions'>
                                    <p className='RouteStart'>{item.startRegionName}</p>
                                    <p className='RouteEnd'>{item.endRegionName}</p>
                                </div>
                            </div>
                            <hr className='HistoryRoutesLine'></hr>
                            <div className='HistoryRoutesPassInfo'>
                                <div className='HistoryDriverProfile'>
                                    <img className='HistoryDriverImage' src={logo}></img>
                                    <div className='HistoryDriverInfo'>
                                        <p className='driverName'>{item.DriverName}</p>
                                    </div>
                                </div>
                               
                                {/*item.trip.hasPassengers ? item.trip.hasPassengers.map( (passenger) => (
                                    <div key={item.id} className='HistoryDriverProfile' style={{left:"5px"}}>
                                        <img className='HistoryDriverImage' src={logo}></img>
                                        <div className='HistoryDriverInfo'>
                                            <p className='driverName'>{item.DriverName}</p>
                                        </div>
                                    </div>
                                )):''*/}
                                <div className='HistoryCarInfo'>
                                    <p className='HistoryCarInfoDate'>{item.actualStartDate.slice(0,-9) + " " + item.actualStartDate.slice(11,-3)}</p>
                                    <p className='HistoryCarInfoCar'>{item.Vehicle}</p>
                                    {item.status == 0 ? <p className='HistoryCarInfoStatus'>Pending</p> : item.status == 1 ? <p className='HistoryCarInfoStatusComplete'>Complete</p>: 
                                    item.status == 2 ? <p className='HistoryCarInfoStatusCanceled'>Cancelled</p>: ''}
                                </div>
                            </div>
                        
                        </div>: ''
                    )):<div>
                        {MyTrips != null && MyTrips.$values.length && MyTrips.$values.length < 0 && routes != null && routes.$values.length && routes.$values.length < 0 ?
                            <p className='NoRoutes'>Κανένα δρομολόγιο</p>:''}
                    </div>}

                    {MyTrips != null && MyTrips.$values.length && MyTrips.$values.length > 0 ? MyTrips.$values.map( (item) => (
                        item.status == 0 ?
                    <div key={item.id} className="HistoryRoutes">
                        {item.status == 0 ? <NavLink style={{textDecoration:"none",
                                                            width: "100%",
                                                            height: "100%",
                                                            position: "absolute",
                                                            left: "0",
                                                            zIndex:"999"}} 
                                    key={item.id} to="/EditMyTrip"         
                                    state={{
                                        id:item.id,
                                        tripId : item.tripId,
                                        driverId: item.offeredByDriverId,
                                        passengerId: item.passengerId,
                                        vehicle: item.vehicleModel,
                                        driverFirstName: item.driverFirstName,
                                        driverLastName: item.driverLastName,
                                        startRegion: item.startRegionName,
                                        endRegion:item.endRegionName,
                                        startDate:item.actualStartDate,
                                        endDate:item.actualEndDate
                                    }}
                            ></NavLink>:''}
                            <div className='HistoryRoutesDiv'>
                                <div className='HistoryRoutesDivImages'>
                                    <img src={greenDot} style={{left:"-1px",position:"relative"}}></img>
                                    <img src={line} style={{height:"10px",top:"5px",position:"relative"}}></img>
                                    <img src={blueDot} style={{position:"relative",top:"10px",left:"-3px"}}></img>
                                </div>
                                <div className='HistoryRoutesDivRegions'>
                                    <p className='RouteStart'>{item.startRegionName}</p>
                                    <p className='RouteEnd'>{item.endRegionName}</p>
                                </div>
                            </div>
                            <hr className='HistoryRoutesLine'></hr>
                            <div className='HistoryRoutesPassInfo'>
                                <div className='HistoryDriverProfile'>
                                    <img className='HistoryDriverImage' src={logo}></img>
                                    <div className='HistoryDriverInfo'>
                                        <p className='driverName'>{item.DriverName}</p>
                                    </div>
                                </div>
                               
                                {/*item.trip.hasPassengers ? item.trip.hasPassengers.map( (passenger) => (
                                    <div key={item.id} className='HistoryDriverProfile' style={{left:"5px"}}>
                                        <img className='HistoryDriverImage' src={logo}></img>
                                        <div className='HistoryDriverInfo'>
                                            <p className='driverName'>{item.DriverName}</p>
                                        </div>
                                    </div>
                                )):''*/}
                                <div className='HistoryCarInfo'>
                                    <p className='HistoryCarInfoDate'>{item.actualStartDate.slice(0,-9) + " " + item.actualStartDate.slice(11,-3)}</p>
                                    <p className='HistoryCarInfoCar'>{item.Vehicle}</p>
                                    {item.status == 0 ? <p className='HistoryCarInfoStatus'>Pending</p> : item.status == 1 ? <p className='HistoryCarInfoStatusComplete'>Complete</p>: 
                                    item.status == 2 ? <p className='HistoryCarInfoStatusCanceled'>Cancelled</p>: ''}
                                </div>
                            </div>
                        
                        </div>:''
                    )):''}

                <h1 className='PendingHistoryRoutesH'>Ιστορικό</h1>
                
                {routes != null && routes.$values.length && routes.$values.length > 0 ? routes.$values.map( (item) => (
                    item && item.status != 0 ?
                    <div key={item.id} className="HistoryRoutes">
                        
                        {/*item.status == 0 ? <NavLink style={{textDecoration:"none",
                                                            width: "100%",
                                                            height: "100%",
                                                            position: "absolute",
                                                            left: "0",
                                                            zIndex:"999"}} 
                                    key={item.id} to="/PassengerRideInfo"         
                                    state={{
                                        id:item.id,
                                        tripId : item.tripId,
                                        driverId: item.offeredByDriverId,
                                        passengerId: item.passengerId,
                                        vehicle: item.vehicle,
                                        driverFirstName: item.driverFirstName,
                                        driverLastName: item.driverLastName
                                    }}
                                ></NavLink>:''*/}
                            <div className='HistoryRoutesDiv'>
                                <div className='HistoryRoutesDivImages'>
                                    <img src={greenDot} style={{left:"-1px",position:"relative"}}></img>
                                    <img src={line} style={{height:"10px",top:"5px",position:"relative"}}></img>
                                    <img src={blueDot} style={{position:"relative",top:"10px",left:"-3px"}}></img>
                                </div>
                                <div className='HistoryRoutesDivRegions'>
                                    <p className='RouteStart'>{item.startRegionName}</p>
                                    <p className='RouteEnd'>{item.endRegionName}</p>
                                </div>
                            </div>
                            <hr className='HistoryRoutesLine'></hr>
                            <div className='HistoryRoutesPassInfo'>
                                <div className='HistoryDriverProfile'>
                                    <img className='HistoryDriverImage' src={logo}></img>
                                    <div className='HistoryDriverInfo'>
                                        <p className='driverName'>{item.DriverName}</p>
                                    </div>
                                </div>
                               
                                {/*item.trip.hasPassengers ? item.trip.hasPassengers.map( (passenger) => (
                                    <div key={item.id} className='HistoryDriverProfile' style={{left:"5px"}}>
                                        <img className='HistoryDriverImage' src={logo}></img>
                                        <div className='HistoryDriverInfo'>
                                            <p className='driverName'>{item.DriverName}</p>
                                        </div>
                                    </div>
                                )):''*/}
                                <div className='HistoryCarInfo'>
                                    <p className='HistoryCarInfoDate'>{item.actualStartDate.slice(0,-9) + " " + item.actualStartDate.slice(11,-3)}</p>
                                    <p className='HistoryCarInfoCar'>{item.Vehicle}</p>
                                    {item.status == 0 ? <p className='HistoryCarInfoStatus'>Pending</p> : item.status == 1 ? <p className='HistoryCarInfoStatusComplete'>Complete</p>: 
                                    item.status == 2 ? <p className='HistoryCarInfoStatusCanceled'>Cancelled</p>: ''}
                                </div>
                            </div>
                        
                        </div>:''
                    )):<div>
                    {MyTrips != null && MyTrips.$values.length && MyTrips.$values.length < 0 && routes != null && routes.$values.length && routes.$values.length < 0 ?
                        <p className='NoRoutes'>Κανένα δρομολόγιο</p>:''}
                    </div>}

                    {MyTrips != null && MyTrips.$values.length && MyTrips.$values.length > 0 ? MyTrips.$values.map( (item) => (
                        item.status != 0 ?
                    <div key={item.id} className="HistoryRoutes">
                        {item.status == 0 ? <NavLink style={{textDecoration:"none",
                                                            width: "100%",
                                                            height: "100%",
                                                            position: "absolute",
                                                            left: "0",
                                                            zIndex:"999"}} 
                                    key={item.id} to="/EditMyTrip"         
                                    state={{
                                        id:item.id,
                                        tripId : item.tripId,
                                        driverId: item.offeredByDriverId,
                                        passengerId: item.passengerId,
                                        vehicle: item.vehicleModel,
                                        driverFirstName: item.driverFirstName,
                                        driverLastName: item.driverLastName,
                                        startRegion: item.startRegionName,
                                        endRegion:item.endRegionName,
                                        startDate:item.actualStartDate,
                                        endDate:item.actualEndDate
                                    }}
                            ></NavLink>:''}
                            <div className='HistoryRoutesDiv'>
                                <div className='HistoryRoutesDivImages'>
                                    <img src={greenDot} style={{left:"-1px",position:"relative"}}></img>
                                    <img src={line} style={{height:"10px",top:"5px",position:"relative"}}></img>
                                    <img src={blueDot} style={{position:"relative",top:"10px",left:"-3px"}}></img>
                                </div>
                                <div className='HistoryRoutesDivRegions'>
                                    <p className='RouteStart'>{item.startRegionName}</p>
                                    <p className='RouteEnd'>{item.endRegionName}</p>
                                </div>
                            </div>
                            <hr className='HistoryRoutesLine'></hr>
                            <div className='HistoryRoutesPassInfo'>
                                <div className='HistoryDriverProfile'>
                                    <img className='HistoryDriverImage' src={logo}></img>
                                    <div className='HistoryDriverInfo'>
                                        <p className='driverName'>{item.DriverName}</p>
                                    </div>
                                </div>
                               
                                {/*item.trip.hasPassengers ? item.trip.hasPassengers.map( (passenger) => (
                                    <div key={item.id} className='HistoryDriverProfile' style={{left:"5px"}}>
                                        <img className='HistoryDriverImage' src={logo}></img>
                                        <div className='HistoryDriverInfo'>
                                            <p className='driverName'>{item.DriverName}</p>
                                        </div>
                                    </div>
                                )):''*/}
                                <div className='HistoryCarInfo'>
                                    <p className='HistoryCarInfoDate'>{item.actualStartDate.slice(0,-9) + " " + item.actualStartDate.slice(11,-3)}</p>
                                    <p className='HistoryCarInfoCar'>{item.Vehicle}</p>
                                    {item.status == 0 ? <p className='HistoryCarInfoStatus'>Pending</p> : item.status == 1 ? <p className='HistoryCarInfoStatusComplete'>Complete</p>: 
                                    item.status == 2 ? <p className='HistoryCarInfoStatusCanceled'>Cancelled</p>: ''}
                                </div>
                            </div>
                        
                        </div>:''
                    )):''}
                
            </div>
        );
}


export default PendingHistoryRoutes