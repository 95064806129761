import React from 'react';
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate } from 'react-router-dom';
import logo from '../images/logo.png'
import axios from 'axios'
import greenDot from '../images/Homepage/green_dot.png'
import blueDot from '../images/Homepage/blue_dot.png'
import line from '../images/Homepage/line.png'
import edit from '../images/Homepage/pencil.png'
import add from '../images/Homepage/add.png'
import de from '../images/Homepage/delete.png'


const token = localStorage.getItem("token");

const EditRoute = () => {
    const navigate = useNavigate();
    //navigate("/EditRoute");
}

const MyRoutes = () => {
        var routes = JSON.parse(sessionStorage.getItem("MyRoutes")) || null;
        const navigate = useNavigate();

        React.useEffect( () => {
            axios.get('https://api.thesscarpool.eu/api/Route/GetMyRoutes', { headers: {"Authorization" : `Bearer ${token}`} })
            .then(res => {
                console.log(res.data);
                //setRoutes(routes => res.data);
                sessionStorage.setItem("MyRoutes",JSON.stringify(res.data));
            }).catch(err => {
                console.log(err);
            });
        });

        const CancelRoute = (routeId) => {
           navigate("/DeleteRoute", {state:{
            routeId:routeId
           }})
        }
       
        var routes = JSON.parse(sessionStorage.getItem("MyRoutes")) || [];
        //console.log(routes[0].trip.status);
        return(
            <div className='History'>
                {routes != null && routes.$values && routes.$values.length > 0 ? routes.$values.map( (item) => (
                    item.deleted == false ?
                    <div key={item.id} className="MyRoutes">
                        <NavLink style={{textDecoration:"none"}} key={item.id} to="/DriverRideInfo"         
                                state={{
                                    id:item.id,
                                    driverId: item.offeredByDriverId,
                                    passengerId: item.passengerId,
                                    vehicle: item.vehicleModel,
                                    driverFirstName: item.driverFirstName,
                                    driverLastName: item.driverLastName,
                                    startRegion:item.startRegionName,
                                    endRegion:item.endRegionName
                                }}
                            >
                            <div className='HistoryRoutesDiv'>
                                <div className='HistoryRoutesDivImages'>
                                    <img src={greenDot} style={{left:"-1px",position:"relative"}}></img>
                                    <img src={line} style={{height:"10px",top:"5px",position:"relative"}}></img>
                                    <img src={blueDot} style={{position:"relative",top:"10px",left:"-3px"}}></img>
                                </div>
                                <div className='HistoryRoutesDivRegions'>
                                    <p className='RouteStart'>{item.startRegionName}</p>
                                    <p className='RouteEnd'>{item.endRegionName}</p>
                                </div>
                            </div>
                            </NavLink>
                            <div className='AddRoute'>
                                <button onClick={
                                    () =>
                                        axios.get('https://api.thesscarpool.eu/api/Trips/GetTripByRouteId/'+item.id, { headers: {"Authorization" : `Bearer ${token}`} })
                                        .then(res => {
                                            console.log(res.data);
                                            //setRoutes(routes => res.data);
                                            sessionStorage.setItem("TripByRouteId",JSON.stringify(res.data));
                                        }).catch(err => {
                                            console.log(err);
                                        }) && navigate("/EditRoute", {state:{
                                            routeId: item.id,
                                            start: item.startRegionName,
                                            end: item.endRegionName,
                                            details: item.routeDetails
                                        }})
                                    }><img src={edit} style={{width:"22px", position:"relative",top:"1px",left:"2px"}}></img></button>
                               

                                <button className='AddRouteDel' onClick={() => CancelRoute(item.id)}></button>
                            </div>
                            <div className='AddRouteNewButton'>
                                <button  onClick={
                                    () =>
                                        navigate("/OfferRouteCalendarStart", {state:{
                                            routeId: item.id,
                                            start: item.startRegionName,
                                            end: item.endRegionName,
                                            details: item.routeDetails
                                        }})
                                    }>Προσθήκη Δρομολογίου</button>
                            </div>
                            
                            {/*<hr className='HistoryRoutesLine'></hr>
                            <div className='HistoryRoutesPassInfo'>
                                <div className='HistoryDriverProfile'>
                                    <img className='HistoryDriverImage' src={logo}></img>
                                    <div className='HistoryDriverInfo'>
                                        <p className='driverName'>{item.offeredByDriver.applicationUser.firstName}</p>
                                    </div>
                                </div>
                                <NavLink onClick={EditRoute(item.routeId)} to="/EditRoute" style={{top:"5px"}} state={{
                                    routeId: item.id,
                                    start: item.startRegionName,
                                    end: item.endRegionName,
                                    details: item.routeDetails
                                }}>Επεξεργασία δρομολογίου</NavLink>
                               
                                {item.trip.hasPassengers.length > 0 ? item.trip.hasPassengers.map( (passenger) => (
                                    <div key={item.id} className='HistoryDriverProfile' style={{left:"5px"}}>
                                        <img className='HistoryDriverImage' src={logo}></img>
                                        <div className='HistoryDriverInfo'>
                                            <p className='driverName'>{item.offeredByDriver.applicationUser.firstName}</p>
                                        </div>
                                    </div>
                                )):''}
                                <div className='HistoryCarInfo'>
                                    <p className='HistoryCarInfoDate'>{item.trip.actualStartDate.slice(0,-9) + " " + item.trip.actualStartDate.slice(11,-3)}</p>
                                    <p className='HistoryCarInfoCar'>{item.offeredByDriver.vehicle.modelName}</p>
                                    <p className='HistoryCarInfoStatus'>{item.trip.status == 0 ? 'Pending' : item.trip.status == 1 ? 'Complete': ''}</p>
                                </div>
                                </div>*/}
                    </div>: <div className='AddRouteNewButton' style={{bottom:"-60px", display: item.deleted ? "none" : "inline-block"}}>
                            <button  onClick={
                                () =>
                                    navigate("/OfferRouteStart")
                            }>Προσθήκη Δρομολογίου</button>
                    </div>
                )):''}

                        <div className='AddRouteNewButton' style={{bottom:"-100px"}}>
                            <button  onClick={
                                () =>
                                    navigate("/OfferRouteStart")
                            }>Προσθήκη Νέου Δρομολογίου</button>
                        </div>
            </div>
        );
}


export default MyRoutes;