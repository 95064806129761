//libraries
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import React from 'react';

const SendMailSuccess = () => {
const [detail, setDetail] = React.useState(null);
const navigate = useNavigate();
const location = useLocation();

const {mail} = location.state;

    const AfterSend = () => {
        navigate("/Login");

       /* var email = document.getElementById('mail');
        axios.post(`https://api.thesscarpool.eu/api/Members/SendMailSuccessword`, {
            "email": email,
        })
          .then(res => {
            console.log(res);
            navigate("/SendMailSuccess");
          }).catch(error => {
            console.log(error.response.data.detail);
            setDetail(error.response.data.detail);
            setTimeout(() => {
                setDetail(null);
            },3000);
          });*/
    }

    return(
        <div className='SendMailSuccessDivMain'>
            <div className='SendMailSuccessDivOne'>
                <h2 className='SendMailSuccessDivH'>Έλεγξε το email σου</h2>
                <p className='SendMailSuccessDivP'>Έχουμε στείλει ένα email στο <p style={{color:"#00AFCD"}}>{mail}</p> με οδηγίες για να αλλάξεις το password σου. Έλεγξε και στην ανεπιθύμητη αλληλογραφία</p>
            </div>
            <div className='SendMailSuccessDivButton'>
                <button onClick={() => AfterSend()}>Συνέχεια</button>
            </div>
            <div className='SomethingWentWrong'>
                <p>{detail}</p>
            </div>
        </div>
    );
}

export default SendMailSuccess;