import logo from '../images/logo.png';
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

function ReRoute(){
    const navigate = useNavigate();
    //const token = localStorage.getItem("token");

    var url_string = window.location.href;
    var url = new URL(url_string);
    var token = url.searchParams.get("token");
    //var token = localStorage.getItem("token");
    var dType = url.searchParams.get("dtype");
   
    
    localStorage.setItem("dtype",dType);

    setTimeout(() => {
        if(token != null){
            axios.get('https://api.thesscarpool.eu/api/Members/GetReservationsHistory', { headers: {"Authorization" : `Bearer ${token}`} })
            .then(res => {
                console.log(res.data);
                //setRoutes(routes => res.data);
                sessionStorage.setItem("PendingHistory",JSON.stringify(res.data));
            }).catch(err => {
                console.log(err);
            });

            axios.get('https://api.thesscarpool.eu/api/Route/GetMyRoutes', { headers: {"Authorization" : `Bearer ${token}`} })
            .then(res => {
                console.log(res.data);
                //setRoutes(routes => res.data);
                sessionStorage.setItem("MyRoutes",JSON.stringify(res.data));
            }).catch(err => {
                console.log(err);
            });

            axios.get('https://api.thesscarpool.eu/api/Members/GetMemberProfile', { headers: {"Authorization" : `Bearer ${token}`} })
            .then(res => {
                console.log(res.data);
                //setRoutes(routes => res.data);
                sessionStorage.setItem("MyProfile",JSON.stringify(res.data));
            }).catch(err => {
                console.log(err);
            });

            axios.get('https://api.thesscarpool.eu/api/Regions/GetAll', { headers: {"Authorization" : `Bearer ${token}`} })
            .then(res => {
                //console.log(res);
                sessionStorage.setItem("Regions", JSON.stringify(res.data));
                //console.log(JSON.parse(sessionStorage.getItem("")));
            })
            .catch(err => {
                console.log(err);
            });

            axios.get('https://api.thesscarpool.eu/api/Members/GetReservationsHistory', { headers: {"Authorization" : `Bearer ${token}`} })
            .then(res => {
                console.log(res.data);
                //setRoutes(routes => res.data);
                sessionStorage.setItem("PendingHistory",JSON.stringify(res.data));
            }).catch(err => {
                console.log(err);
            });

            axios.get('https://api.thesscarpool.eu/api/Trips/GetMyTrips', { headers: {"Authorization" : `Bearer ${token}`} })
            .then(res => {
                console.log(res.data);
               
                //setRoutes(routes => res.data);
                sessionStorage.setItem("MyTrips",JSON.stringify(res.data));
            }).catch(err => {
                console.log(err);
            });

            axios.get('https://api.thesscarpool.eu/api/Members/GetFriends', { headers: {"Authorization" : `Bearer ${token}`} })
            .then(res => {
                console.log(res);
                //console.log(res.data.hasFriends.$values.length);
                //console.log(res.data.isFriendWith.$values.length);
                if(res.data.isFriendWith.$values.length > 0){
                    sessionStorage.setItem("FriendsIds",JSON.stringify(res.data.isFriendWith));
                }

                if(res.data.hasFriends.$values.length > 0){
                    sessionStorage.setItem("FriendsIds",JSON.stringify(res.data.hasFriends));
                }
            })
            .catch(err => {
                console.log(err);
            });

            axios.get('https://api.thesscarpool.eu/api/TripReservation/NotificationForReservation', { headers: {"Authorization" : `Bearer ${token}`} })
            .then(res => {
                console.log(res);
                sessionStorage.setItem("Notifications",JSON.stringify(res.data));
            })
            .catch(err => {
                console.log(err);
            });
            
            navigate("/SearchRoute");
        }else{
            navigate("/OnboardingOne");
        }
    }, 3000);
}

const Splash = () => {

    ReRoute();


    return(
        <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p class="splashP">ThessCarPool</p>
        </header>
    );
}

export default Splash;