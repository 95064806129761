import React from 'react';
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate } from 'react-router-dom';
import logo from '../images/logo.png'
import greenDot from '../images/Homepage/green_dot.png'
import blueDot from '../images/Homepage/blue_dot.png'
import line from '../images/Homepage/line.png'
import clock from '../images/Homepage/clock.png'
import seat from '../images/Homepage/seat.png'


const RouteResults = () => {

    var routes = JSON.parse(sessionStorage.getItem("searchDriveResults")) || [];
    console.log(routes);
    
    return(
        <div className='RouteResultsMainDiv'>
            <NavLink className='WhiteBack' to="/SearchRoute"></NavLink>
            <div className='RouteResultsblackEclipse'>
            {routes != null && routes.$values.length && routes.$values.length > 0 && routes.$values.map( (item) =>(
                      
                            <div key={item.id} className="routes">
                                  <NavLink style={{textDecoration:"none"}} key={item.id} to="/ConfirmRoute"         
                                        state={{
                                            id:item.id,
                                            driverFirstName: item.driverFirstName,
                                            driverLastName: item.driverLastName,
                                            routeId: item.route,
                                            startRegion: item.startRegionName,
                                            endRegion: item.endRegionName,
                                            carModel: item.vehicleModel,
                                            date:      item.actualStartDate
                                        }}
                                    >
                                    <div className='driverProfile'>
                                        <img className='driverImage' src={logo}></img>
                                        <div className='driverInfo'>
                                            <p className='driverName'>{item.driverFirstName +" "+ item.driverLastName}</p>
                                            <p className='driverCar'>{item.vehicleModel}</p>
                                        </div>
                                        <p></p>
                                    </div>
                                    <div className='RoutesDiv'>
                                        <div className='RoutesDivImages'>
                                            <img src={greenDot} style={{left:"-1px",position:"relative"}}></img>
                                            <img src={line} style={{height:"30px",top:"5px",position:"relative"}}></img>
                                            <img src={blueDot} style={{position:"relative",top:"10px",left:"-3px"}}></img>
                                        </div>
                                        <div className='RoutesDivRegions'>
                                            <p>{item.startRegionName}</p>
                                            <p style={{top:"20px",position:"relative"}}>{item.endRegionName}</p>
                                        </div>
                                    </div>
                                    {/*<div className='routesDiv'>
                                        <p className='RouteStart'>{item.startRegionName}</p>
                                        <p className='RouteEnd'>{item.endRegionName}</p>
                                    </div>*/}
                                    <div className='passInfo'>
                                        <img src={clock}></img>
                                        <p className='time'>{item.actualStartDate.slice(11,-3)}</p>
                                        <p className='time'>{item.actualEndDate.slice(11,-3)}</p>
                                        <img src={seat} className="seatImg"></img>
                                        <p className='seats'>2 θέσεις</p>
                                    </div>
                                </NavLink>
                            </div>
            ))}
            </div>
        </div>
    );
}

export default RouteResults;