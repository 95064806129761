import React from 'react';
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';

const SuccessfullRegister = () => {
    const navigate = useNavigate();

    const Ok = () => {
        navigate("/Login");
    }

    return(
        <div className='SuccessfullReservationMainDiv'>
            <h1>Εγγραφήκατε επιτυχώς</h1>
            <p>Για να επιβεβαιώσατε την εγγραφή σας έχουμε στείλει ένα email (παρακαλούμε ελέγξτε και την ανεπιθύμητη αλληλογραφία σας) που περιέχει ένα σύνδεσμο (link) που θα ενεργοποιήσει το λογαριασμό σας</p>
            <div className='SuccessfullButton'>
                <button onClick={Ok}>Σύνδεση</button>
            </div>
        </div>
    );
}

export default SuccessfullRegister;