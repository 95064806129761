import EllipseOne from '../images/OnBoarding/Ellipse125.png'
import EllipseTwo from '../images/OnBoarding/Ellipse126.png'
import Rectangle from '../images/OnBoarding/Rectangle82.png'

//libraries
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes } from 'react-router-dom';

const OnBoardingTwo = () => {
    return(
        <div className='OnBoardingThree'>
            <NavLink className='OnBoardingThreeNext' to="/Login">Επόμενο</NavLink>
            <div className='OnBoardingThreeDiv'>
                <h5 className='OnBoardingThreeH'>Όλα στο κινητό σου</h5>
                <p className='OnBoardingThreeP'>Βρες και κλείσε γρήγορα τα δρομολόγια που θέλεις και μάθε ότι χρειάζεσαι για αυτά</p>
            </div>
            <div className='OnBoardingThreeDivImg'>
                <img src={EllipseOne}></img>
                <img src={EllipseTwo}></img>
                <img src={Rectangle}></img>
            </div>
            <div className='OnBoardingThreeDivLogin'>
                <p>Έχεις ήδη λογαριασμό; <NavLink to="/Login"> Συνδέσου εδώ.</NavLink></p> 
            </div>
        </div>
    );
}

export default OnBoardingTwo;