//libraries
import { BrowserRouter, Switch, Route, NavLink, Link, Navbar, Routes, useNavigate } from 'react-router-dom';
import axios from 'axios';
import React from 'react';
import glass from '../images/Homepage/search.png';
import greenDot from '../images/Homepage/green_dot.png'
import blueDot from '../images/Homepage/blue_dot.png'
import line from '../images/Homepage/line.png'
import OfferRoute from '../Homepages/OfferRoute/OfferRoute';
import SearchRoute from '../Homepages/SearchRoute/SearchRoute';

//files
import calendar from '../images/Homepage/CombinedShape.png'
import passenger from '../images/Homepage/Union.png'
import { act } from 'react-dom/test-utils';


const token = localStorage.getItem("token");

const getIt = () => {
    
    axios.get('https://api.thesscarpool.eu/api/Members/GetReservationsHistory', { headers: {"Authorization" : `Bearer ${token}`} })
    .then(res => {
        console.log(res.data);
        //setRoutes(routes => res.data);
        sessionStorage.setItem("PendingHistory",JSON.stringify(res.data));
    }).catch(err => {
        console.log(err);
    });

    axios.get('https://api.thesscarpool.eu/api/Route/GetMyRoutes', { headers: {"Authorization" : `Bearer ${token}`} })
    .then(res => {
        console.log(res.data);
        //setRoutes(routes => res.data);
        sessionStorage.setItem("MyRoutes",JSON.stringify(res.data));
    }).catch(err => {
        console.log(err);
    });
}


const Homepage = () => {
    const [activeSearch, setActiveSearch] = React.useState(true);
    const [activeOffer, setActiveOffer] = React.useState(false);
    const navigate = useNavigate();
   
    getIt();


    const OfferDrive = () => {
        setActiveOffer(activeOffer => true);
        setActiveSearch(activeSearch => false);
        navigate("/MyRoutes");
    }

    const SearchForDrive = () => {
        setActiveSearch(activeSearch => true);
        setActiveOffer(activeOffer => false);
        navigate("/SearchRoute");
    }

    return(
        <div className='HomepageMainDiv'>
            <div className='OfferOrGive'>
                <button onClick={() => SearchForDrive()} id="offerDrive" style={{background: window.location.pathname == "/SearchRoute" ? "#13CF97" : "#00AFCD",
                                                                                opacity:window.location.pathname == "/SearchRoute" ? 1 : 0.5}}>Αναζητώ διαδρομή</button>
                <button onClick={() => OfferDrive()} id="giveDrive" style={{background: window.location.pathname == "/OfferRoute" ? "#13CF97" : "#00AFCD",
                                                                            opacity:window.location.pathname == "/OfferRoute" ? 1 : 0.5}}>Προσφέρω διαδρομή</button>
            </div>
            {window.location.pathname == "/OfferRoute" ? <OfferRoute/> : window.location.pathname == "/SearchRoute" ? <SearchRoute /> : ''}
        </div>
    );
}

export default Homepage;